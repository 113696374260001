import PropTypes from 'prop-types';
import React from 'react';

import styles from './Content.scss';

function Content({ children }) {
    return (
        <section className={styles.root}>
            <article className={styles.panel}>{children}</article>
        </section>
    );
}

Content.propTypes = {
    children: PropTypes.any.isRequired,
};

export default Content;
