import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { verifyAccount, logout, states } from 'stillnovel/store/auth/actions';
import reduxForm from 'redux-form/es/reduxForm';
import getFormValues from 'redux-form/es/getFormValues';
import Field from 'redux-form/es/Field';
import Button from 'stillnovel/components/UI/Button';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import Section from 'stillnovel/components/UI/Section';
import Text from 'stillnovel/components/UI/Text';
import { useMount } from 'react-use';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import styles from './Auth.scss';

const required = value =>
    value || typeof value === 'number' ? undefined : 'Required';

const minLength = min => value =>
    value && value.length < min
        ? `Must be ${min} characters or more`
        : undefined;

const minLength6 = minLength(6);

const matchPasswords = (value, values) => {
    if (values.password && values.passwordc !== values.password) {
        return 'Passwords must match';
    }
    return false;
};

const Verification = ({
    hasError,
    authError,
    values,
    handleSubmit,
    valid,
    pristine,
    submitting,
    logout,
}) => {
    const { userId, verificationToken } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useMount(() => {
        logout();
    });

    const handleFormSubmit = e => {
        e.preventDefault();
        dispatch(
            verifyAccount(
                {
                    userId,
                    verificationToken,
                    ...values,
                },
                () => {
                    navigate('/');
                }
            )
        );
    };

    return (
        <>
            <Text tag="h3" theme="form-header-alt">
                Complete Registration
            </Text>
            {hasError && (
                <Text tag="p" isError>
                    {authError}
                </Text>
            )}
            <Section className={styles['auth-verification']}>
                <Form
                    onSubmit={handleFormSubmit}
                    className={styles['auth-verification-form']}
                >
                    <div className={styles['row-verification']}>
                        <Text
                            tag="label"
                            theme="body1-alt"
                            htmlFor="password"
                            className={styles.label}
                        >
                            Create a password (required)
                        </Text>
                        <Field
                            component={FieldInput}
                            className={styles['auth-verification-input']}
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            validate={[required, minLength6]}
                            autoComplete="new-password"
                            showErrorText
                        />
                        <Field
                            component={FieldInput}
                            className={styles['auth-verification-input']}
                            type="password"
                            name="passwordc"
                            placeholder="Confirm password"
                            validate={[required, matchPasswords]}
                            autoComplete="new-password"
                            showErrorText
                        />
                    </div>
                    <div className={styles['row-verification']}>
                        <Text
                            tag="label"
                            theme="body1-alt"
                            htmlFor="firstName"
                            className={styles.label}
                        >
                            Your Name
                        </Text>
                        <Field
                            component={FieldInput}
                            className={styles['auth-verification-input']}
                            type="firstName"
                            name="firstName"
                            id="firstName"
                            placeholder="First name"
                            autoComplete="firstName"
                        />
                        <Field
                            component={FieldInput}
                            className={styles['auth-verification-input']}
                            type="lastName"
                            name="lastName"
                            placeholder="Last name"
                            autoComplete="lastName"
                        />
                    </div>
                    <div className={styles['row-verification']}>
                        <Button
                            block
                            type="submit"
                            disabled={!valid || pristine || submitting}
                        >
                            {submitting ? 'Sending...' : 'Submit'}
                        </Button>
                    </div>
                </Form>
            </Section>
        </>
    );
};

Verification.propTypes = {
    authError: PropTypes.any,
    handleSubmit: PropTypes.func,
    hasError: PropTypes.bool,
    logout: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    values: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        values: getFormValues('verify')(state),

        hasError: state.auth.state === states.ACCOUNT_VERIFICATION_ERROR,
        authError: state.auth.error,
    };
};

const mapDispatchToProps = dispatch => ({
    handleSubmit: values => {
        dispatch(verifyAccount(values));
    },
    logout: () => {
        dispatch(logout());
    },
});

const VerificationForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(Verification);

export default reduxForm({
    form: 'verify',
    touchOnBlur: true,
})(VerificationForm);
