import React from 'react';
import PropTypes from 'prop-types';
import Link from 'stillnovel/components/UI/Link';
import cx from 'classnames';

import styles from './Button.scss';

const Button = ({
    block,
    className,
    href,
    large,
    outline,
    small,
    theme,
    to,
    underline,
    xlarge,
    xsmall,
    xxlarge,
    ...props
}) => {
    const classNames = cx(styles.btn, styles[theme], className, {
        [styles.block]: block,
        [styles.large]: large,
        [styles.outline]: outline,
        [styles.small]: small,
        [styles.underline]: underline,
        [styles.xlarge]: xlarge,
        [styles.xsmall]: xsmall,
        [styles.xxlarge]: xxlarge,
    });

    if (to || href) {
        return <Link {...props} className={classNames} to={to} href={href} />;
    }
    return <button {...props} className={classNames} />;
};

Button.propTypes = {
    block: PropTypes.bool,
    className: PropTypes.string,
    href: PropTypes.string,
    large: PropTypes.bool,
    outline: PropTypes.bool,
    small: PropTypes.bool,
    theme: PropTypes.oneOf(Object.keys(styles)),
    to: PropTypes.string,
    underline: PropTypes.bool,
    xlarge: PropTypes.bool,
    xsmall: PropTypes.bool,
    xxlarge: PropTypes.bool,
};

Button.defaultProps = {
    block: false,
    className: null,
    href: null,
    large: false,
    outline: false,
    small: false,
    theme: null,
    to: null,
    underline: false,
    xlarge: false,
    xsmall: false,
    xxlarge: false,
};

export default Button;
