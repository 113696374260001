import React, { Component } from 'react';
import keymirror from 'keymirror';
import SN from 'stillnovel/services';
import moment from 'moment';
import Money from 'dinero.js';

import OrderOverviewPresentational from './OrderOverview';

export const loadingStates = keymirror({
    LOADING: null,
    LOADED: null,
    ERROR: null,
});

class OrderOverview extends Component {
    state = {
        loadState: loadingStates.LOADING,
    };

    handleCancelOrder = async () => {
        await SN.order.cancel(this.props.match.params.id);
        this.fetchOrder();
    };

    async fetchOrder() {
        try {
            const order = await SN.order.getById(this.props.match.params.id);
            const charge = order.stripeCharge
                ? {
                      cardType: order.stripeCharge.object.source.brand,
                      cardLastFour: `.... ${order.stripeCharge.object.source.last4}`,
                  }
                : {};
            this.setState({
                loadState: loadingStates.LOADED,
                ...charge,
                items: order.items,
                giftCard: order.giftCard,
                amountGiftCardApplied: Money({
                    amount: order.amountGiftCardApplied,
                }).toFormat(),
                status: order.status,
                orderId: order.id,
                shippingOption: order.shippingOption.title,
                grandTotal: Money({ amount: order.totalGrand }).toFormat(),
                totalDue: Money({ amount: order.totalDue }).toFormat(),
                user: order.user,
                endDate: moment(order.createdAt).utc().add(1, 'hours'),
            });
        } catch (e) {
            this.setState({
                loadState: loadingStates.ERROR,
            });
        }
    }

    componentDidMount() {
        this.fetchOrder();
    }

    render() {
        return (
            <OrderOverviewPresentational
                {...this.state}
                {...this.props}
                onCancel={this.handleCancelOrder}
            />
        );
    }
}

export default OrderOverview;
