import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loader from 'stillnovel/components/UI/Loader';
import { states as appStates } from 'stillnovel/store/auth/actions';
import Auth from 'stillnovel/components/App/Auth/Auth';
import AuthLayout from 'stillnovel/components/layouts/AuthLayout/AuthLayout';
import AuthOverlay from 'stillnovel/components/App/Auth/Overlay';

const Protected = ({ state, children, overlay }) => {
    switch (state) {
        case appStates.LOGGED_IN:
            return <Fragment>{children}</Fragment>;
        case appStates.UNAUTHENTICATED_USER:
            return <Fragment>{children}</Fragment>;
        case appStates.AUTHENTICATED:
        case appStates.LOGGING_IN:
            return <Loader fullscreen text="Logging in..." />;
        case appStates.LOGGED_OUT:
        case appStates.LOGIN_FAILURE:
            return overlay ? (
                <Fragment>
                    <AuthOverlay />
                    {children}
                </Fragment>
            ) : (
                <AuthLayout>
                    <Auth />
                </AuthLayout>
            );
        default:
            return (
                <div>
                    <p>Unrecognized Auth State</p>
                </div>
            );
    }
};

Protected.propTypes = {
    children: PropTypes.any,
    overlay: PropTypes.bool,
    state: PropTypes.any,
};

Protected.defaultProps = {
    overlay: false,
};

const mapStateToProps = state => ({
    state: state.auth.state,
});

export default compose(connect(mapStateToProps))(Protected);
