import { loggingIn, configure } from 'stillnovel/store/auth/actions';

/**
 * sets up auth.
 */
const setupAuth = (store, config) => {
    store.dispatch(configure(config));
    store.dispatch(loggingIn());
};

export { setupAuth };
