import { takeEvery, call, put } from 'redux-saga/effects';
import { actionTypes } from 'stillnovel/store/project/actions';
import d from 'debug';

const debug = d('App:Project-Meta-Handler');

export const handleImageMeta = function* ({ payload }) {
    const { value } = payload;

    yield put({
        type: actionTypes.UPDATE_META_VALUE,
        payload: {
            field: 'image',
            value: (value && value.src) || undefined,
        },
    });
};

export const handleDerived = (scope, handler) =>
    function* (action) {
        if (!action.payload?.builderHandle) {
            return false;
        }

        if (
            scope instanceof RegExp &&
            !scope.test(action.payload.builderHandle)
        ) {
            return false;
        }

        if (
            typeof scope === 'string' &&
            action.payload.builderHandle !== scope
        ) {
            return false;
        }

        if (action.payload.field in handler) {
            debug(
                `\`${action.payload.field}\` changed using derived handler \`${
                    handler[action.payload.field].name
                }\``
            );
            yield call(handler[action.payload.field], action);
        }
    };

export const scopedMetaUpdateHandler = (scope, handler) => {
    return [
        takeEvery(actionTypes.UPDATE_META_VALUE, handleDerived(scope, handler)),
    ];
};
