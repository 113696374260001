import Client from 'shopify-buy/index.unoptimized.umd';
import getConfig from 'core/config';
import { useQuery, gql } from '@apollo/client';

const FETCH_PRODUCT_BY_HANDLE = gql`
    query fetchProductByHandle($handle: String!) {
        productByHandle(handle: $handle) {
            id
            variants(first: 20) {
                edges {
                    node {
                        id
                        title
                        price
                        availableForSale
                        sku
                        linkedProductionProductId: metafield(
                            namespace: "custom"
                            key: "linked_production_product_id"
                        ) {
                            id
                            value
                        }
                    }
                }
            }
        }
    }
`;

const client = Client.buildClient({
    storefrontAccessToken: getConfig('shopifyStorefrontAccessToken'),
    domain: getConfig('shopifyStorefrontDomain'),
});

export const useShopify = () => {
    return client;
};

export const useFetchByHandle = handle => {
    const { loading, error, data } = useQuery(FETCH_PRODUCT_BY_HANDLE, {
        skip: !handle,
        variables: { handle },
    });

    if (data && !loading && !error) {
        return {
            ...data?.productByHandle,
            variants: data?.productByHandle?.variants?.edges?.map(
                ({ node }) => node
            ),
        };
    }

    return false;
};
