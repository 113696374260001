import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from 'stillnovel/components/UI/Text';

import styles from './Loader.scss';

const Loader = ({ align, fullscreen, inversed, text, theme }) => {
    return (
        <span
            className={cx(styles.loader, {
                [styles['loader-center']]: align === 'center',
                [styles['loader-right']]: align === 'right',
                [styles['loader-left']]: align === 'left',
                [styles['loader-fullscreen']]: fullscreen,
                [styles['loader-inversed']]: inversed,
            })}
        >
            <span className={cx(styles['loader-indicator'], styles[theme])} />
            {text && <Text className={styles['loader-text']}>{text}</Text>}
        </span>
    );
};

Loader.propTypes = {
    align: PropTypes.string,
    fullscreen: PropTypes.bool,
    inversed: PropTypes.bool,
    text: PropTypes.string,
    theme: PropTypes.string,
};

Loader.defaultProps = {
    align: 'center',
    fullscreen: false,
    inversed: false,
    text: null,
    theme: 'default',
};

export default Loader;
