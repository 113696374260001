import PropTypes from 'prop-types';
import React from 'react';
import Button from 'stillnovel/components/UI/Button';
import Link from 'stillnovel/components/UI/Link';
import Text from 'stillnovel/components/UI/Text';
import Img from 'stillnovel/components/UI/Img';

import styles from './ProductCTA.scss';

const ProductCTA = ({
    buttonLabel,
    buttonRoute,
    href,
    imageSrc,
    title,
    desc,
    index,
}) => {
    const buttonProps = {
        to: buttonRoute,
        href,
    };
    return (
        <li
            className={styles.root}
            style={{
                '--animation-order': index + 1,
            }}
        >
            <figure className={styles.figure}>
                <Link to={buttonRoute} className={styles.link}>
                    <Img
                        src={imageSrc}
                        className={styles.image}
                        alt={title}
                        priority={index === 0}
                    />
                </Link>
            </figure>
            <figcaption className={styles.copy}>
                <Text theme="title" className={styles.title}>
                    {title}
                </Text>
                <Text tag="figcaption" className={styles.desc}>
                    {desc}
                </Text>
            </figcaption>
            <Button {...buttonProps} outline block>
                {buttonLabel}
            </Button>
        </li>
    );
};

ProductCTA.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    buttonRoute: PropTypes.string,
    desc: PropTypes.string.isRequired,
    href: PropTypes.string,
    imageSrc: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};

export default ProductCTA;
