/* eslint-disable require-atomic-updates */
import 'aws-sdk/lib/credentials/cognito_identity_credentials';
import jwtDecode from 'jwt-decode';
import AWS from 'aws-sdk';
import ExtendableError from 'extendable-error';
import d from 'debug';
import moment from 'moment';
import cookies from 'js-cookie';
import getConfig from 'core/config';

import { InvalidRefreshTokenException } from './exceptions';
// import Client from 'shopify-buy/index.unoptimized.umd';

export class AuthException extends ExtendableError {}

const debug = d('App:Auth');

const noop = () => {};

export default class AuthenticationService {
    refreshTries = 0;
    awsCognitoFails = 0;
    api;
    config;
    refreshTimerID;
    onSessionExpire;
    IdentityPoolId = 'us-east-1:c96d3c8d-2ed6-4f31-a3b9-bfa60f7d5b2d';
    adminIdentityPoolId = 'us-east-1:b3b08d0a-cacf-4378-bd50-c8a3c3537085';

    constructor(apiService, config) {
        this.api = apiService;
        this.config = config;
        this.onSessionExpire = config.onSessionExpire || noop;

        this.api.interceptors.request.use(
            async config => {
                // get JWT token

                if (config.withSession) {
                    this.pending = this.pending
                        ? this.pending
                        : this.getSession();
                    const session = await this.pending;
                    delete this.pending;

                    if (session && session?.user.role !== 'guest') {
                        config.headers.Authorization = session.tokens.access;

                        if (this.guestCache()) {
                            config.withCredentials = true;
                        }
                        // Check if withUser to prepend user users/${userId}/ route
                        if (config.withUser) {
                            config.baseURL = `${config.baseURL}users/${session.user.id}/`;
                        }
                    } else if (
                        session.user.role === 'guest' &&
                        session.user.cognitoIdentityId
                    ) {
                        config.withCredentials = true;

                        // config.headers.Authorization = `${AWS.config.credentials.sessionToken}`;
                        config.baseURL = `${config.baseURL}guests/${session.user.cognitoIdentityId}/`;
                    } else {
                        debug('No session, bail out');
                        this.config.onError('no session');
                    }
                }
                return config;
            },
            error => {
                // Do something with request error
                debug('Auth service ', error);
                if (this.config.onError) {
                    this.config.onError(error);
                }
                return Promise.reject(error);
            }
        );
    }

    get tempStore() {
        return window.sessionStorage;
    }

    async register(email) {
        const response = await this.api.post(
            '/register',
            {
                email: email.toLowerCase(),
            },
            {
                withCredentials: true,
            }
        );
        return response.data.user;
    }

    async registerFacebook(email, token) {
        const response = await this.api.post(
            '/register/facebook',
            {
                email,
                accessToken: token,
            },
            {
                withCredentials: true,
            }
        );
        return response.data.user;
    }

    async authenticateFacebook(facebookAppId, facebookToken) {
        delete AWS.config.credentials;

        const response = await this.api.post(
            '/auth/facebook',
            {
                facebookAppId,
                accessToken: facebookToken,
            },
            {
                withCredentials: true,
            }
        );

        //this.storeSession(response.data);
        return response.data.user;
    }

    async resetPassword(attributes) {
        const response = await this.api.post(
            '/auth/reset-password',
            attributes
        );

        return response.data.user;
    }

    async resetPasswordRequest(email) {
        const response = await this.api.post('/auth/reset-password-request', {
            email: email.toLowerCase(),
        });
        return response.data;
    }

    async verify(attributes) {
        const response = await this.api.post('/register/verify', attributes);
        return response.data.user;
    }

    async verifyEmail({ userId, verificationToken }) {
        const response = await this.api.post('/me/verify', {
            userId,
            verificationToken,
        });
        return response.data.user;
    }

    async authenticate(email, password) {
        delete AWS.config.credentials;

        const response = await this.api.post(
            '/auth/login',
            {
                email: email.toLowerCase(),
                password,
            },
            { withCredentials: true } // send refresh token cookie
        );

        return response.data.user;
    }

    async getAnonymousSession() {
        const response = await this.api.post(
            '/auth/anonymous',
            {},
            { withCredentials: true } // send refresh token cookie
        );

        return response.data.user;
    }

    async status(email) {
        const response = await this.api.post('/auth/status', {
            email: email.toLowerCase(),
        });
        return response.data.state || 'ERROR';
    }

    async resendVerificationEmail(email) {
        const response = await this.api.post('/auth/resend-verification', {
            email: email.toLowerCase(),
        });

        return response.data.success || false;
    }

    async refreshAccessToken() {
        if (this.refreshTries >= 6) {
            await this.api.post('/auth/logout', null, {
                withCredentials: true, // send refresh token cookie
            });
            cookies.remove(getConfig('cookieNameAuthToken'));
            return false;
        }
        try {
            this.refreshTries++;

            await this.api.post('/auth/token', null, {
                withCredentials: true, // send refresh token cookie
            });

            debug('%c Refreshed JWT Token', 'color:Green');
            this.refreshTries = 0;

            return true;
        } catch (e) {
            await new Promise(resolve =>
                setTimeout(() => resolve(), 500 * this.refreshTries)
            );
            return this.refreshAccessToken();
        }
    }

    async logout() {
        if (this.session()) {
            await this.api.post('/auth/logout', null, {
                withCredentials: true, // send refresh token cookie
            });
            delete AWS.config.credentials;
            cookies.remove(getConfig('cookieNameAuthToken'));
        }
    }

    async initSessionGuestUser() {
        console.log('initSessionGuestUser');

        console.log(AWS.config.credentials);

        AWS.config.credentials = new AWS.CognitoIdentityCredentials(
            {
                IdentityPoolId: this.IdentityPoolId,
            },
            { region: 'us-east-1' }
        );

        await AWS.config.credentials.getPromise();

        const session = {
            cognitoIdentityId: AWS.config.credentials.params.IdentityId,
            user: {
                id: AWS.config.credentials.params.IdentityId,
                role: 'guest',
                cognitoIdentityId: AWS.config.credentials.params.IdentityId,
            },
        };

        this.tempStore.setItem('SN_guestUser', JSON.stringify(session));
        return session;
    }

    guestCache() {
        const stored = this.tempStore.getItem('SN_guestUser');
        if (stored) {
            return JSON.parse(stored) || false;
        }
        return false;
    }

    async getSession() {
        const session = await this.session();

        // if (!session) {
        //     // Setup guest user
        //     session = await this.initSessionGuestUser();
        // }

        // Create/Update CognitoIdentity instance and refresh in needed
        if (!AWS.config.credentials) {
            await this.setupCognitoIdentity();
        }

        if (AWS.config.credentials) {
            const awsexp = moment(AWS.config.credentials.expireTime);

            debug(
                `%c AWS ${
                    AWS.config.credentials.needsRefresh()
                        ? 'Expired ❌'
                        : `Valid ✅  - Expires ${awsexp.format(
                              'MM/D/YYYY h:mm:ss a z'
                          )}`
                }`,
                `color:${
                    AWS.config.credentials.needsRefresh() ? 'Red' : '#3388ff'
                }`
            );
        }

        if (session.user.role === 'guest') {
            return session;
        }

        const jwt = jwtDecode(session.tokens.access);
        const currentTime = Date.now().valueOf() / 1000;
        const exp = moment(jwt.exp * 1000);
        const refreshExpires = session.refreshExpires || 0;
        const refreshExp = moment(refreshExpires);

        const expiredJWT = jwt.exp < currentTime;
        const expiredRefresh = refreshExpires * 1000 < currentTime;

        debug(
            `%c Refresh ${
                expiredRefresh
                    ? 'Expired ❌- Log out...'
                    : `Valid ✅  - Expires ${refreshExp.format(
                          'MM/D/YYYY h:mm:ss a z'
                      )}`
            }`,
            `color:${expiredRefresh ? 'Red' : '#3388ff'}`
        );

        clearTimeout(this.refreshTimerID);

        this.refreshTimerID = setTimeout(() => {
            debug('Refresh token expired (Timer)');
            this.onSessionExpire();
        }, refreshExpires - Date.now().valueOf());

        if (expiredRefresh) {
            debug('Refresh token expired');
            throw new InvalidRefreshTokenException();
        }

        debug(
            `%c JWT ${
                expiredJWT
                    ? 'Expired ❌ - Refreshing...'
                    : `Valid ✅  - Expires ${exp.format(
                          'MM/D/YYYY h:mm:ss a z'
                      )}`
            }`,
            `color:${expiredJWT ? 'Red' : '#3388ff'}`
        );

        /* check if expired */
        if (expiredJWT) {
            try {
                this.refreshAccessTokenRequest = this.refreshAccessTokenRequest
                    ? this.refreshAccessTokenRequest
                    : this.refreshAccessToken();

                const response = await this.refreshAccessTokenRequest;
                delete this.refreshAccessTokenRequest;

                if (response) {
                    return await this.getSession();
                }
                return false;
            } catch (e) {
                debug(
                    'Fetch new Token Failed (long term refresh token expired)'
                );
                throw new InvalidRefreshTokenException();
            }
        }

        return session;
    }

    async setupCognitoIdentity() {
        const session = await this.session();

        if (session.user.role !== 'guest') {
            const cognitoToken = session.tokens.cognito;

            AWS.config.credentials = new AWS.CognitoIdentityCredentials(
                {
                    IdentityPoolId:
                        session.user.role === 'admin'
                            ? this.adminIdentityPoolId
                            : this.IdentityPoolId,
                    IdentityId: session.cognitoIdentityId,
                    Logins: {
                        'cognito-identity.amazonaws.com': cognitoToken,
                    },
                },
                { region: 'us-east-1' }
            );
        } else {
            AWS.config.credentials = new AWS.CognitoIdentityCredentials(
                {
                    IdentityPoolId: this.IdentityPoolId,
                    IdentityId: session.cognitoIdentityId,
                },
                { region: 'us-east-1' }
            );
        }

        if (!AWS.config.credentials) {
            throw new Error('No AWS credentials initialized');
        }
    }

    async refreshAWSSession() {
        const session = await this.session();

        if (session?.tokens?.cognito) {
            const cognitoToken = session.tokens.cognito;
            // Update Logins with latest token from server
            if (
                AWS.config.credentials.params.Logins[
                    'cognito-identity.amazonaws.com'
                ] !== cognitoToken
            ) {
                debug('Updated AWS token credentials');
                AWS.config.credentials.params.Logins[
                    'cognito-identity.amazonaws.com'
                ] = cognitoToken;
            }
        }
        if (AWS.config.credentials.needsRefresh()) {
            try {
                await AWS.config.credentials.getPromise();
            } catch (e) {
                const success = await this.refreshAccessToken();
                if (success) {
                    return this.refreshAWSSession();
                }
            }
        }
    }

    async session() {
        const cookie = cookies.get(getConfig('cookieNameAuthToken'));
        const cached = (cookie && JSON.parse(cookie)) || false;
        const guestCache = this.guestCache();

        if (cached) {
            debug('Using Authenticated Cache', cached);
            return cached;
        }

        if (guestCache) {
            debug('Using Guest Cache', guestCache);
            return guestCache;
        }
        debug('Initializing  Guest Session');

        return await this.initSessionGuestUser();
    }
}
