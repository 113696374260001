import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Loader from 'stillnovel/components/UI/Loader';
import { verifyEmail, states } from 'stillnovel/store/auth/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

const Verification = ({ verifyEmail, hasError, authState, authError }) => {
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const { userId, verificationToken } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId || !verificationToken) {
            setError('Invalid request');
            return;
        }

        verifyEmail(
            {
                userId,
                verificationToken,
            },
            () => {
                navigate('/');
            }
        );
    }, [userId, verificationToken]);

    // useEffect(() => {
    //     if (authState === states.LOGGED_IN) {
    //         //     navigate('/');
    //     }
    // }, [authState]);

    if (authState === states.EMAIL_VERIFICATION_ERROR) {
        return <p>{authError}</p>;
    }

    return <Loader fullscreen text="Verifying..." />;
};

Verification.propTypes = {
    authError: PropTypes.any,
    authState: PropTypes.any,
    hasError: PropTypes.any,
    verifyEmail: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        authState: state.auth.state,
        authError: state.auth.error,
    };
};

const mapDispatchToProps = { verifyEmail };

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
