import React, { memo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import cx from 'classnames';
import CloseButton from 'stillnovel/components/UI/CloseButton';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import Text from 'stillnovel/components/UI/Text';

import icons from './icons';
import styles from './Header.scss';

const checkoutSteps = [
    {
        iconType: 'cart',
        slug: '/cart',
    },
    {
        iconType: 'payment',
        slug: '/checkout',
    },
    {
        iconType: 'complete',
        slug: '/order',
    },
];

const Progress = ({ icon }) => {
    const location = useLocation();
    return (
        <div className={styles.progress}>
            {icon ? (
                <SvgIcon
                    iconSrc={icons}
                    iconType={icon}
                    className={styles['progress-icon']}
                />
            ) : (
                checkoutSteps.map((step, i) => {
                    const classNames = cx(styles['progress-icon'], {
                        [styles.active]: location.pathname.includes(step.slug),
                    });
                    return (
                        <React.Fragment key={i}>
                            <SvgIcon
                                className={classNames}
                                iconSrc={icons}
                                iconType={step.iconType}
                            />
                            {i < checkoutSteps.length - 1 && (
                                <SvgIcon iconType="chevron" />
                            )}
                        </React.Fragment>
                    );
                })
            )}
        </div>
    );
};

const Header = props => (
    <div className={styles.heading}>
        <CloseButton className={styles['close-btn']} to="/" />
        <Text tag="h3" className={styles.title} theme="form-header-alt">
            {props.heading ? (
                props.heading
            ) : (
                <Routes>
                    <Route exact path="/cart" render={() => 'Cart'} />
                    <Route exact path="/checkout" render={() => 'Payment'} />
                </Routes>
            )}
        </Text>
        <Progress {...props} />
        {props.hasBanner && (
            <Text theme="body1-alt" tag="h5" className={styles.banner}>
                FREE ground shipping on all orders!
            </Text>
        )}
    </div>
);

Header.defaultProps = {
    hasBanner: false,
    heading: null,
    icon: false,
};

export default memo(Header);
