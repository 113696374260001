import getConfig from 'core/config';

export const NAV_LINKS = [
    [
        {
            href: `${getConfig('marcomBaseUrl')}/#products`,
            label: 'Art Prints',
            links: [
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/birth-stat-art-print`,
                    label: 'Birth Stat',
                },
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/wedding-art-print`,
                    label: 'Wedding',
                },
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/travel-art-print`,
                    label: 'Travel',
                },
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/all-about-you-print`,
                    label: 'All About You',
                },
                {
                    href: `${getConfig(
                        'clientUrl'
                    )}/project/all-about-you?isMemorial=true`,
                    label: 'Memorial',
                },
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/pet-art-print`,
                    label: 'Pet',
                },
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/couples-art-print`,
                    label: 'Couples',
                },
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/engagement-art-print`,
                    label: 'Engagement',
                },
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/mom-art-print`,
                    label: 'Mom',
                },
                {
                    href: `${getConfig(
                        'marcomBaseUrl'
                    )}/products/dad-art-print`,
                    label: 'Dad',
                },
            ],
        },
    ],
    [
        {
            href: `${getConfig('marcomBaseUrl')}/products/custom-framed-prints`,
            label: 'Giclée Prints',
        },
    ],
    [
        {
            onClick: () => window.Reamaze.popup(),
            label: 'Support & Chat',
            className: 'panel-link-secondary',
        },
        {
            href: `${getConfig('marcomBaseUrl')}/gift-cards`,
            label: 'Gift Cards',
            className: 'panel-link-secondary',
        },
        {
            href: `${getConfig('marcomBaseUrl')}/faq`,
            label: 'FAQ',
            className: 'panel-link-secondary',
        },
    ],
];
