import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Wrapper.scss';

const Wrapper = ({
    children,
    className,
    color,
    fluid,
    padding,
    transparent,
    ...props
}) => {
    const classNames = cx(styles.root, className, {
        [styles.white]: color === 'white',
        [styles.cream]: color === 'cream',
        [styles.beige]: color === 'beige',
        [styles.pale]: color === 'pale',
        [styles.fluid]: fluid,
        [styles.padding]: padding,
        [styles.transparent]: !color || transparent,
    });
    return (
        <div className={classNames} {...props}>
            <div className={styles['wrapper-inner']}>{children}</div>
        </div>
    );
};

Wrapper.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    fluid: PropTypes.bool,
    padding: PropTypes.bool,
    transparent: PropTypes.bool,
};

Wrapper.defaultProps = {
    className: null,
    color: '',
    fluid: false,
    padding: false,
    transparent: false,
};

export default Wrapper;
