import React, { useRef, Fragment, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import getConfig from 'core/config';
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import Button from 'stillnovel/components/UI/Button';
import CloseButton from 'stillnovel/components/UI/CloseButton';
import Link from 'stillnovel/components/UI/Link';
import Logo from 'stillnovel/components/UI/Logo';
import Text from 'stillnovel/components/UI/Text';
import { setNavState, states as appStates } from 'stillnovel/store/app/actions';

import Panel from './Panel';
import styles from './Navigation.scss';
import { NAV_LINKS } from './config';

const Hamburger = () => (
    <span className={styles.hamburger}>
        <svg
            enableBackground="new 0 0 32 32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="currentColor"
                d="m4 10h24c1.104 0 2-.896 2-2s-.896-2-2-2h-24c-1.104 0-2 .896-2 2s.896 2 2 2zm24 4h-24c-1.104 0-2 .896-2 2s.896 2 2 2h24c1.104 0 2-.896 2-2s-.896-2-2-2zm0 8h-24c-1.104 0-2 .896-2 2s.896 2 2 2h24c1.104 0 2-.896 2-2s-.896-2-2-2z"
            />
        </svg>
    </span>
);

const handleClickPanels = close => e => {
    if (e.currentTarget === e.target) {
        close();
    }
};

const NavLinks = () => {
    const location = useLocation();

    function renderChildLinks({ links }) {
        return links
            ? links.map((link, i) => (
                  <Link
                      key={i}
                      href={link.href}
                      className={cx(
                          styles['panel-link'],
                          styles['panel-link-child']
                      )}
                  >
                      {link.label}
                  </Link>
              ))
            : null;
    }
    return NAV_LINKS.map((links, i) => {
        return (
            <div key={i} className={styles.wrapper}>
                {links.map((link, i) => {
                    const classNames = cx(
                        styles['panel-link'],
                        link.className && styles[`${link.className}`],
                        {
                            [styles['panel-link-disabled']]: !link.href,
                            [styles.selected]: location.pathname === link.href,
                        }
                    );
                    return !link.href ? (
                        <Fragment key={i}>
                            <Text className={classNames} onClick={link.onClick}>
                                {link.label}
                            </Text>
                            {renderChildLinks(link)}
                        </Fragment>
                    ) : (
                        <Fragment key={i}>
                            <Link href={link.href} className={classNames}>
                                {link.label}
                            </Link>
                            {renderChildLinks(link)}
                        </Fragment>
                    );
                })}
            </div>
        );
    });
};

const Navigation = ({ hasHero, ...props }) => {
    const scroller = useRef(null);
    const dispatch = useDispatch();

    const cartItemCount = useSelector(
        state => state.cart?.lineItems?.length || 0
    );

    const shopOpen = useSelector(
        state => state.app.navState === appStates.NAV_OPEN_SHOP
    );
    const closed = useSelector(
        state => state.app.navState === appStates.NAV_CLOSED
    );

    const openShop = useCallback(() => {
        dispatch(setNavState(appStates.NAV_OPEN_SHOP));
    }, [dispatch]);

    const close = useCallback(() => {
        dispatch(setNavState(appStates.NAV_CLOSED));
    }, [dispatch]);

    const toggleShop = useCallback(() => {
        shopOpen ? close() : openShop();
    }, [shopOpen, close, openShop]);

    useEffect(() => {
        shopOpen
            ? disableBodyScroll(scroller.current)
            : enableBodyScroll(scroller.current);
        return function cleanup() {
            clearAllBodyScrollLocks();
        };
    }, [scroller, shopOpen]);

    const rootClassNames = cx(styles.root, {
        [styles['menu-open']]: !closed,
        [styles['has-hero']]: hasHero,
    });
    const panelsClassNames = cx(styles.panels, {
        [styles['panel-open']]: !closed,
    });
    return (
        <Fragment>
            <header className={rootClassNames}>
                <div className={styles.header}>
                    <span className={styles['nav-buttons']}>
                        <Button theme="button" onClick={toggleShop}>
                            <Hamburger />{' '}
                            <span className={styles['nav-button']}>Shop</span>
                        </Button>
                    </span>
                    <span className={styles['account-buttons']}>
                        {cartItemCount > 0 && (
                            <Button
                                theme="button"
                                key="cart"
                                to="/cart"
                                className={styles['nav-button']}
                            >
                                <span className={styles['cart-count-wrapper']}>
                                    Cart
                                    <span className={styles['cart-count']}>
                                        {cartItemCount}
                                    </span>
                                </span>
                            </Button>
                        )}
                    </span>
                </div>
            </header>
            <nav
                onClick={handleClickPanels(close)}
                className={panelsClassNames}
            >
                <Panel
                    active={shopOpen}
                    onTransitionEnd={e => {
                        if (e.propertyName === 'transform' && !shopOpen) {
                            // Reset scroll position on panel transition
                            scroller.current.scrollTop = 0;
                        }
                    }}
                >
                    <Fragment>
                        <Link
                            href={`${getConfig('marcomBaseUrl')}`}
                            className={styles.logo}
                        >
                            <Logo square />
                        </Link>
                        <div className={styles.scroller} ref={scroller}>
                            <NavLinks {...props} />
                        </div>
                    </Fragment>
                </Panel>
                <CloseButton onClick={close} className={styles['close-btn']} />
            </nav>
        </Fragment>
    );
};

Navigation.propTypes = {
    hasHero: PropTypes.bool,
};

export default Navigation;
