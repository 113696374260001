import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { loadImage } from 'stillnovel/utils/ImageLoader';

import styles from './ProductPreview.scss';

function getImage(code = '') {
    const sizes = {
        'R-MGS, 10x10': [
            require('./images/SN-SFP/10x10Brass.webp'),
            'sfp-10x10',
        ],
        'R-MBS,10x10': [
            require('./images/SN-SFP/10x10BlackMetal.webp'),
            'sfp-10x10',
        ],
        'R-GBS, 10x10': [
            require('./images/SN-SFP/10x10BlackGallery.webp'),
            'sfp-10x10',
        ],
        'R-GMS, 10x10': [
            require('./images/SN-SFP/10x10MapleGallery.webp'),
            'sfp-10x10',
        ],
        'R-GWS, 10x10': [
            require('./images/SN-SFP/10x10WhiteGallery.webp'),
            'sfp-10x10',
        ],

        'R-MGS, 11x15': [
            require('./images/SN-SFP/11x15Brass.webp'),
            'sfp-11x15',
        ],
        'R-MBS, 11x15': [
            require('./images/SN-SFP/11x15BlackMetal.webp'),
            'sfp-11x15',
        ],
        'R-GBS, 11x15': [
            require('./images/SN-SFP/11x15BlackGallery.webp'),
            'sfp-11x15',
        ],
        'R-GMS, 11x15': [
            require('./images/SN-SFP/11x15MapleGallery.webp'),
            'sfp-11x15',
        ],
        'R-GWS, 11x15': [
            require('./images/SN-SFP/11x15WhiteGallery.webp'),
            'sfp-11x15',
        ],

        'R-MGS, 15x11': [
            require('./images/SN-SFP/15x11Brass.webp'),
            'sfp-15x11',
        ],
        'R-MBS, 15x11': [
            require('./images/SN-SFP/15x11BlackMetal.webp'),
            'sfp-15x11',
        ],
        'R-GBS, 15x11': [
            require('./images/SN-SFP/15x11BlackGallery.webp'),
            'sfp-15x11',
        ],
        'R-GMS, 15x11': [
            require('./images/SN-SFP/15x11MapleGallery.webp'),
            'sfp-15x11',
        ],
        'R-GWS, 15x11': [
            require('./images/SN-SFP/15x11WhiteGallery.webp'),
            'sfp-15x11',
        ],

        'WPH16-White': [
            require('./images/SN-AP-16x20/cleat-white.webp'),
            'ap-16x20',
        ],
        'WPH16-Black': [
            require('./images/SN-AP-16x20/cleat-black.webp'),
            'ap-16x20',
        ],
        'WPH16-Natural': [
            require('./images/SN-AP-16x20/cleat.webp'),
            'ap-16x20',
        ],

        'R-MGS, 16x20': [
            require('./images/SN-AP-16x20/brass.webp'),
            'ap-16x20',
        ],
        'R-MBS, 16x20': [
            require('./images/SN-AP-16x20/black.webp'),
            'ap-16x20',
        ],
        'R-GBS, 16x20': [
            require('./images/SN-AP-16x20/gallery-black.webp'),
            'ap-16x20',
        ],
        'R-GMS, 16x20': [
            require('./images/SN-AP-16x20/gallery-maple.webp'),
            'ap-16x20',
        ],
        'R-GWS, 16x20': [
            require('./images/SN-AP-16x20/gallery-white.webp'),
            'ap-16x20',
        ],

        'R-MGS, 20x16': [
            require('./images/SN-SFP/20x16Brass.webp'),
            'sfp-20x16',
        ],
        'R-MBS, 20x16': [
            require('./images/SN-SFP/20x16BlackMetal.webp'),
            'sfp-20x16',
        ],
        'R-GBS, 20x16': [
            require('./images/SN-SFP/20x16BlackGallery.webp'),
            'sfp-20x16',
        ],
        'R-GMS, 20x16': [
            require('./images/SN-SFP/20x16MapleGallery.webp'),
            'sfp-20x16',
        ],
        'R-GWS, 20x16': [
            require('./images/SN-SFP/20x16WhiteGallery.webp'),
            'sfp-20x16',
        ],

        'R-MGS, 20x24': [
            require('./images/SN-SFP/20x24Brass.webp'),
            'sfp-20x24',
        ],
        'R-MBS, 20x24': [
            require('./images/SN-SFP/20x24BlackMetal.webp'),
            'sfp-20x24',
        ],
        'R-GBS, 20x24': [
            require('./images/SN-SFP/20x24BlackGallery.webp'),
            'sfp-20x24',
        ],
        'R-GMS, 20x24': [
            require('./images/SN-SFP/20x24MapleGallery.webp'),
            'sfp-20x24',
        ],
        'R-GWS, 20x24': [
            require('./images/SN-SFP/20x24WhiteGallery.webp'),
            'sfp-20x24',
        ],

        'R-MGS, 24x20': [
            require('./images/SN-SFP/24x20Brass.webp'),
            'sfp-24x20',
        ],
        'R-MBS, 24x20': [
            require('./images/SN-SFP/24x20BlackMetal.webp'),
            'sfp-24x20',
        ],
        'R-GBS, 24x20': [
            require('./images/SN-SFP/24x20BlackGallery.webp'),
            'sfp-24x20',
        ],
        'R-GMS, 24x20': [
            require('./images/SN-SFP/24x20MapleGallery.webp'),
            'sfp-24x20',
        ],
        'R-GWS, 24x20': [
            require('./images/SN-SFP/24x20WhiteGallery.webp'),
            'sfp-24x20',
        ],
    };

    return sizes[code] || [false, false];
}

function ProductPreview({ active, children, sku }) {
    const [imageDefault, imageClassName] = getImage(sku);

    const [image, setImage] = useState(imageDefault);

    useEffect(() => {
        const runEffect = async () => {
            const [newImage] = getImage(sku);
            if (active && newImage) {
                await loadImage(newImage);
            }
            setImage(newImage);
        };
        runEffect();
    }, [active, sku]);

    if (!active || !image) {
        return children;
    }

    return (
        <div className={cx(styles.preview, styles[imageClassName])}>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'contain',
                }}
                className={styles.overlay}
            />
            <div className={styles.frame}>
                <div className={styles.art}>{children}</div>
            </div>
        </div>
    );
}

ProductPreview.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    sku: PropTypes.string,
};

ProductPreview.defaultProps = {
    active: false,
    sku: '',
};

export default memo(ProductPreview);
