import React from 'react';
import loadGoogleMaps from 'stillnovel/utils/loadGoogleMaps';
import retry from 'stillnovel/utils/retry';
import Loader from 'stillnovel/components/UI/Loader';
import Resolve from 'stillnovel/components/UI/Resolve';

const LoadableArtPrint = props => (
    <Resolve
        promise={() =>
            Promise.all([
                retry(() =>
                    import(
                        /* webpackChunkName: "artprint", webpackPreload: true */ './ArtPrint'
                    )
                ),
                loadGoogleMaps(),
            ])
        }
    >
        {resolved => {
            if (!resolved) {
                return <Loader fullscreen text="Initializing builder..." />;
            }
            const [ArtPrint, google] = resolved;
            return <ArtPrint.default google={google} {...props} />;
        }}
    </Resolve>
);

export default LoadableArtPrint;
