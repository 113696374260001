import PropTypes from 'prop-types';
import React from 'react';
import CloseButton from 'stillnovel/components/UI/CloseButton';

import Auth from '../Auth';

import styles from './Overlay.scss';

const AuthOverlay = ({ handleClose }) => {
    return (
        <div className={styles.root}>
            <div className={styles.inner}>
                <CloseButton
                    onClick={handleClose}
                    className={styles['close-btn']}
                />
                <Auth handleClose={handleClose} />
            </div>
        </div>
    );
};

AuthOverlay.propTypes = {
    handleClose: PropTypes.func,
};

export default AuthOverlay;
