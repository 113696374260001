import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Panel.scss';

const Panel = ({ active, children, onTransitionEnd }) => {
    const classNames = cx(styles.root, {
        [styles.active]: active,
    });

    return (
        <div className={classNames} onTransitionEnd={onTransitionEnd}>
            <div className={styles.links}>{children}</div>
        </div>
    );
};

Panel.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onTransitionEnd: PropTypes.func,
};

export default memo(Panel);
