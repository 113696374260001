import React, { useEffect, createContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import formActions from 'redux-form/es/actions';
import { Helmet } from 'react-helmet';
import {
    useParams,
    useNavigate,
    useLocation,
    useSearchParams,
} from 'react-router-dom';
import { usePrevious, useUnmount } from 'react-use';
import { useFetchByHandle } from 'stillnovel/hooks/useShopify';
import { useWait } from 'stillnovel/hooks/useWait';
import Text from 'stillnovel/components/UI/Text';
import { submit } from 'redux-form';
import {
    fetchProject,
    initProject,
    resetProject,
} from 'stillnovel/store/project/actions';
import { MainLayout } from 'stillnovel/components/layouts';

import NotFoundProject from '../NotFound/NotFoundProject';

import { useKeyboardSaveProject } from './useKeyboardSaveProject';
import config from './config';
import styles from './Project.scss';

export const ProductContext = createContext();

export const ProductConsumer = ProductContext.Consumer;

function useQuery() {
    return Object.fromEntries(new URLSearchParams(useLocation().search));
}

const Project = () => {
    const { handle: handleParam, step } = useParams();
    const query = useQuery();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const wait = useWait();
    const isID = !config[handleParam];

    const project = useSelector(state => state.project);

    useKeyboardSaveProject(project);

    const isLoading = useSelector(state => state.project.isLoading);
    const isError = useSelector(state => state.project.isError);

    const isNewPrev = usePrevious(project.isNew);
    const builderHandle = project?.project?.builderHandle || handleParam; //GTG
    const isFormInitialized = useSelector(
        state => !!state.form.project?.registeredFields
    );

    const projectBuilder = config[builderHandle];
    const product = useFetchByHandle(projectBuilder?.shopifyProductHandle);

    const isLoaded = product && !isLoading;

    const isInitialized = !!(
        project.project?.shopifyProductHandle &&
        project.project?.builderHandle &&
        project.project?.shopifyProductId
    );

    useEffect(() => {
        if (isFormInitialized && isLoaded) {
            if (searchParams.get('validate')) {
                // slight delay to make it obvious to usr
                wait(300).then(() => dispatch(submit('project')));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormInitialized, isLoaded, searchParams, dispatch]);

    const projectDefaults = useMemo(
        () => ({
            ...projectBuilder?.projectDefaults,
            ...query,
        }),
        [query, projectBuilder]
    );

    const loadProject = id => {
        dispatch(fetchProject(id));
    };

    useEffect(() => {
        if ((isInitialized && handleParam && !isLoading) || isID) {
            loadProject(handleParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isLoaded && !isInitialized && !isID) {
            dispatch(
                initProject({
                    shopifyProductHandle: projectBuilder?.shopifyProductHandle,
                    builderHandle,
                    shopifyProductId: product.id,
                    metadata: projectDefaults,
                })
            );
        }
    }, [
        builderHandle,
        dispatch,
        isID,
        isInitialized,
        isLoaded,
        product,
        projectBuilder,
        projectDefaults,
    ]);

    useEffect(() => {
        if (isNewPrev === true && project.isNew === false) {
            // Handles url replace once saved *Logged in
            navigate(
                `/project/${project.project.id}${step ? `/${step}` : ''}`,
                {
                    replace: true,
                    state: { scroll: false },
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.isNew, isNewPrev, project.isAnonymous]);

    useUnmount(() => {
        dispatch(formActions.destroy('project'));
        dispatch(resetProject());
    });

    if (
        isID &&
        !isLoading &&
        isError &&
        typeof project?.project?.id === 'undefined'
    ) {
        return (
            <MainLayout>
                <NotFoundProject />
            </MainLayout>
        );
    }

    if (!isLoaded && !isInitialized) {
        return (
            <div className={styles.root}>
                <Text theme="body1-alt">Loading Project...</Text>
            </div>
        );
    }

    // No component found / Unsupported SKU
    if (!projectBuilder && isLoaded) {
        return (
            <div className={styles.root}>
                <Text theme="body1-alt">
                    No product handle: `{handleParam}`
                </Text>
            </div>
        );
    }

    // Find SKU
    const ProjectBuildComponent = projectBuilder?.component;

    // No component found / Unsupported SKU
    if (!ProjectBuildComponent) {
        return (
            <div className={styles.root}>
                <Text theme="body1-alt">
                    No Component to handle: {handleParam}
                </Text>
            </div>
        );
    }

    return (
        <ProductContext.Provider value={product}>
            <Helmet>
                <title>{projectBuilder?.parentCategory}</title>
                <meta
                    name="description"
                    content={`Build your own ${projectBuilder?.parentCategory}.`}
                />
            </Helmet>
            <ProjectBuildComponent
                id={project?.project?.id}
                product={product}
                project={project}
                projectDefaults={projectDefaults}
                isLoading={isLoading}
            />
        </ProductContext.Provider>
    );
};

export default Project;
