import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Button from 'stillnovel/components/UI/Button';
import Logo from 'stillnovel/components/UI/Logo';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import useScrollDirection from 'core/hooks/useScrollDirection';
import { useLocation } from 'react-router';

import styles from './Subnav.scss';

const AccountSubnav = ({ forceShow }) => {
    const scrollDirection = useScrollDirection();
    const hide = !forceShow ? scrollDirection === 'down' : false;
    const location = useLocation();

    return (
        <nav
            className={cx(styles.root, {
                [styles.hide]: hide,
            })}
        >
            <div className={styles.inner}>
                <Button
                    theme="body1"
                    className={cx(
                        styles.link,
                        location.pathname === '/' && styles.active
                    )}
                    to="/"
                >
                    <span className={styles.icon}>
                        <Logo square />
                    </span>
                    Projects
                </Button>
                <Button
                    theme="body1"
                    className={cx(
                        styles.link,
                        location.pathname === '/orders' && styles.active
                    )}
                    href="https://shop.stillnovel.com/account"
                >
                    <span className={styles.icon}>
                        <SvgIcon iconType="box" />
                    </span>
                    Account
                </Button>
                <Button
                    theme="body1"
                    className={styles.link}
                    onClick={() => window.Reamaze.popup()}
                >
                    <span className={styles.icon}>
                        <SvgIcon iconType="chat" />
                    </span>
                    Support
                </Button>
            </div>
        </nav>
    );
};

AccountSubnav.propTypes = {
    forceShow: PropTypes.bool,
};

export default AccountSubnav;
