import React from 'react';
import { Helmet } from 'react-helmet';
import { NAV_LINKS } from 'stillnovel/components/App/Navigation/config';
import Link from 'stillnovel/components/UI/Link';
import Text from 'stillnovel/components/UI/Text';

import styles from './NotFound.module.scss';

export default function NotFound() {
    return (
        <div className={styles.root}>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <div className={styles.inner}>
                <Text tag="h1" theme="jumbo">
                    Oops!
                </Text>
                <Text tag="h3" theme="title">
                    We can&#39;t seem to find the page you&#39;re looking for.
                </Text>
                <section className={styles.content}>
                    <Text tag="p" theme="body1-alt">
                        Some helpful links instead:
                    </Text>
                    <ul>
                        {NAV_LINKS.flat().map(({ href, label }) => (
                            <Text
                                key={label}
                                theme="button"
                                tag="li"
                                className={styles.listItem}
                            >
                                <Link href={href}>{label}</Link>
                            </Text>
                        ))}
                    </ul>
                </section>
            </div>
        </div>
    );
}
