import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState, memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useSWR from 'swr';
import { useMount } from 'react-use';
import getConfig from 'core/config';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import Button from 'stillnovel/components/UI/Button';
import HGroup from 'stillnovel/components/UI/HGroup';
import Loader from 'stillnovel/components/UI/Loader';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import Text from 'stillnovel/components/UI/Text';
import Wrapper from 'stillnovel/components/UI/Wrapper';
import SN from 'stillnovel/services';
import CloseButton from 'stillnovel/components/UI/CloseButton';
import ArtPrintPreview from 'stillnovel/components/App/ArtPrintPreview';
import config from 'stillnovel/components/App/Project/config';
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { getProjects, deleteProject } from 'stillnovel/store/projects/actions';

import Content from '../Content';
import icons from '../../Checkout/Header/icons';

import ProductCTA from './ProductCTA';
import styles from './Dashboard.scss';

const productCTABirth = require('./images/product-cta-birth.jpg');
const productCTAWedding = require('./images/product-cta-wedding.jpg');
const productCTATravel = require('./images/product-cta-travel.jpg');
const productCTAAllAboutYou = require('./images/product-cta-aay.jpg');
const productCTAMom = require('./images/product-cta-mom.jpg');
const productCTADad = require('./images/product-cta-dad.jpg');
const productCTASimpleFramedPrints = require('./images/product-cta-simple-framed-prints.jpg');
const productCTAPet = require('./images/product-cta-pet.jpg');
const productCTACouples = require('./images/product-cta-couples.jpg');
const productCTAEngagement = require('./images/product-cta-engagement.jpg');

const products = [
    {
        title: 'Birth Stat Print',
        desc: 'Our 16x20" Custom Birth Stat print tells the story of your child and their birthday with thoughtful design and personalized details. Starting at $65.',
        buttonLabel: 'Create',
        buttonRoute: `/project/birth-stat`,
        imageSrc: productCTABirth,
    },
    {
        title: 'Wedding Art Print',
        desc: 'Every couple and wedding is unique. Our 16x20” Signature Wedding Print celebrates the essence and details of your day. Starting at $65.',
        buttonLabel: 'Create',
        buttonRoute: `/project/wedding`,
        imageSrc: productCTAWedding,
    },
    {
        title: 'Travel Art Print',
        desc: 'Celebrate the destination and the journey. Our 16x20” Signature Travel Print tells the story of a trip and details that made it memorable. Starting at $65.',
        buttonLabel: 'Create',
        buttonRoute: `/project/travel`,
        imageSrc: productCTATravel,
    },
    {
        title: 'All About You Art Print',
        desc: 'Suitable for all ages, this 16x20” print tells the story of a loved one and what makes them special with fun facts and sweet details. Starting at $65',
        buttonLabel: 'Create',
        buttonRoute: `/project/all-about-you`,
        imageSrc: productCTAAllAboutYou,
    },
    {
        title: 'Mom Print',
        desc: 'Celebrate Mom with a 16x20” Parent Print that tells her story as a mom with sweet and sentimental details. Starting at $65.',
        buttonLabel: 'Create',
        buttonRoute: `/project/parent?subject=mom`,
        imageSrc: productCTAMom,
    },
    {
        title: 'Dad Print',
        desc: 'Our 16x20” Dad prints are nostalgic, funny and sentimental. The perfect gift! Starting at $65.',
        buttonLabel: 'Create',
        buttonRoute: `/project/parent?subject=dad`,
        imageSrc: productCTADad,
    },
    {
        title: 'Pet Print',
        desc: 'Our 16x20” Custom Pret print print tells the story of your child and their birthday with thoughtful design and personalized details. Starting at $65.',
        buttonLabel: 'Create',
        buttonRoute: `/project/pet`,
        imageSrc: productCTAPet,
    },
    {
        title: 'Couples Art Print',
        desc: 'Your love story. Our 16x20” Couples Print highlights all the sweet details that make a couple unique. Starting at $65.',
        buttonLabel: 'Create',
        buttonRoute: `/project/couples`,
        imageSrc: productCTACouples,
    },
    {
        title: 'Engagement Art Print',
        desc: 'It all started with a question. Our 16x20” Signature Engagement print tells the story of a couple and their proposal date. Starting at $65.',
        buttonLabel: 'Create',
        buttonRoute: `/project/engagement`,
        imageSrc: productCTAEngagement,
    },
    {
        title: 'Custom Framed Prints',
        desc: 'Introducing our collection of companion frames in curated sizes and groupings. Order alone or as part of a pairing with the Signature Art Print.',
        buttonLabel: 'Select Size',
        href: `${getConfig('marcomBaseUrl')}/products/custom-framed-prints`,
        imageSrc: productCTASimpleFramedPrints,
    },
];

const ProjectTile = memo(({ project, deleteProject }) => {
    const dispatch = useDispatch();
    const [deleteState, setDeleteState] = useState('');

    const inCart = useSelector(state =>
        state.cart.lineItems.find(item => {
            return (
                item.customAttributes.find(attr => attr.key === '_projectId')
                    ?.value === project.id
            );
        })
    );

    useEffect(() => {
        if (deleteState === 'delete') {
            dispatch(deleteProject(project.id));
        }
    }, [deleteProject, deleteState, dispatch, project.id]);

    return (
        <li className={styles.tile}>
            {inCart && (
                <Button
                    to="/cart"
                    title="View in Cart"
                    className={styles['cart-icon']}
                >
                    <SvgIcon iconSrc={icons} iconType={'cart'} />
                </Button>
            )}
            <div className={styles['tile-inner']}>
                <div className={styles['tile-section']}>
                    <ArtPrintPreview
                        {...project}
                        sku={project?.shopifyProductId}
                        thumbnail
                        inSitu
                    />
                </div>
                <div className={styles['tile-section']}>
                    <div className={styles['tile-info']}>
                        <div>
                            <Text theme="title">
                                {config[project?.builderHandle]?.dashboardTitle(
                                    project
                                )}
                            </Text>
                            {project.title && (
                                <Text className={styles.title} theme="subhead">
                                    {project.title}
                                </Text>
                            )}
                            <Text
                                theme="quote"
                                className={styles['updated-at']}
                            >
                                {`Updated ${moment(
                                    project.updatedAt
                                ).fromNow()}`}
                            </Text>
                        </div>
                        <div>
                            <Button
                                small
                                block
                                outline
                                className={styles['action-btn']}
                                to={`/project/${project.id}`}
                            >
                                Edit
                            </Button>
                            <Button
                                small
                                block
                                outline
                                className={styles['action-btn']}
                                href={inCart ? `/cart` : null}
                                onClick={() =>
                                    !inCart ? setDeleteState('confirm') : null
                                }
                            >
                                {inCart ? 'In Cart' : 'Delete'}
                            </Button>
                        </div>
                    </div>
                    {(deleteState === 'confirm' ||
                        deleteState === 'delete') && (
                        <div className={styles.deletePrompt}>
                            <Text theme="title" className={styles.text}>
                                {`Are you sure you want to permanently delete ${config[
                                    project?.builderHandle
                                ]?.dashboardTitle(project)}?`}
                            </Text>
                            <section>
                                <Button
                                    xsmall
                                    outline
                                    onClick={() => setDeleteState('')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    xsmall
                                    outline
                                    disabled={deleteState === 'delete'}
                                    onClick={() => setDeleteState('delete')}
                                >
                                    {deleteState === 'delete'
                                        ? 'Deleting...'
                                        : 'Yes, Delete'}
                                </Button>
                            </section>
                            <Text theme="quote">This cannot be undone.</Text>
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
});

ProjectTile.displayName = 'ProjectTile';

ProjectTile.propTypes = {
    cartItems: PropTypes.array,
    deleteProject: PropTypes.func,
    products: PropTypes.any,
    productsKeyed: PropTypes.any,
    project: PropTypes.shape({
        builderHandle: PropTypes.string,
        id: PropTypes.string,
        metadata: PropTypes.shape({
            style: PropTypes.string,
        }),
        product: PropTypes.shape({
            title: PropTypes.string,
        }),
        project: PropTypes.string,
        shopifyProductHandle: PropTypes.any,
        shopifyProductId: PropTypes.string,
        title: PropTypes.string,
        updatedAt: PropTypes.string,
    }),
    user: PropTypes.shape({
        cognitoIdentityId: PropTypes.string,
    }),
};

const Dashboard = () => {
    const overlayRef = useRef(null);
    const [productCTAsVisible, setProductCTAsVisibility] = useState(false);

    const projects = useSelector(state => state.projects.items);
    const isLoading = useSelector(state => state.projects.isLoading);
    const user = useSelector(state => state.auth.user);

    const dispatch = useDispatch();

    // Fetch all products in your shop
    const { data: shopifyProducts } = useSWR('products', () =>
        SN.shopifyClient.product.fetchAll()
    );

    const productsKeyed =
        shopifyProducts?.reduce((a, o) => {
            a[o.handle] = o;
            const keyed = o.variants.reduce((a, o) => {
                a[o.id] = o;
                if (o.handle) {
                    a[o.handle] = o;
                }
                return a;
            }, a);
            return { ...a, ...keyed };
        }, {}) || {};

    useMount(() => {
        dispatch(getProjects());
    });

    useEffect(() => {
        const overlay = overlayRef?.current;
        if (overlay) {
            productCTAsVisible
                ? disableBodyScroll(overlay)
                : enableBodyScroll(overlay);
        }
        return function cleanup() {
            clearAllBodyScrollLocks();
        };
    });

    function renderProductCTAs() {
        return (
            <ul className={styles['products-list']}>
                {products.map((product, i) => (
                    <ProductCTA {...product} key={i} index={i} />
                ))}
            </ul>
        );
    }

    function renderProjects() {
        const sortedProjects = [...projects].sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
        });

        return (
            <div className={styles.content}>
                <header className={styles.header}>
                    <Text tag="h2" theme="form-header">
                        My Projects
                    </Text>
                    <Button
                        block
                        outline
                        xsmall
                        onClick={() =>
                            setProductCTAsVisibility(!productCTAsVisible)
                        }
                    >
                        + Create
                    </Button>
                </header>
                <ul key="tiles" className={styles.tiles}>
                    {sortedProjects.map(project => (
                        <ProjectTile
                            key={project.id}
                            project={project}
                            productsKeyed={productsKeyed}
                            user={user}
                            deleteProject={deleteProject}
                        />
                    ))}
                </ul>
            </div>
        );
    }

    function renderLoading() {
        return (
            <Text tag="p">
                <Loader
                    align="left"
                    theme="small"
                    text="Fetching projects..."
                />
            </Text>
        );
    }

    function renderNoProjects() {
        return (
            <div className={styles['no-projects-view']}>
                <Text tag="h2" theme="form-header">
                    Welcome to Still Novel
                </Text>
                {renderProductCTAs()}
            </div>
        );
    }

    function renderContent() {
        if (projects.length > 0) {
            return renderProjects();
        } else if (projects.length === 0 && !isLoading) {
            return renderNoProjects();
        }
        return renderLoading();
    }

    return (
        <Fragment>
            <Helmet>
                <title>Projects</title>
            </Helmet>
            <Wrapper color="cream">
                <HGroup>
                    <Text tag="h1" theme="display">
                        Projects{' '}
                        {projects.length > 0 && (
                            <Text theme="body1-alt" className={styles.count}>
                                ({projects.length})
                            </Text>
                        )}
                    </Text>
                </HGroup>
            </Wrapper>
            <Wrapper color="white">
                <Content>{renderContent()}</Content>
            </Wrapper>
            {productCTAsVisible && (
                <div className={styles.overlay}>
                    <div ref={overlayRef} className={styles['overlay-inner']}>
                        <div className={styles['overlay-close']}>
                            <CloseButton
                                onClick={() => setProductCTAsVisibility(false)}
                            >
                                Return to Projects
                            </CloseButton>
                        </div>
                        <header>
                            <Text theme="title" tag="h1">
                                Which product would you like to create?
                            </Text>
                        </header>
                        {renderProductCTAs()}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default Dashboard;
