import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import Countdown from 'react-countdown-moment';
import Obfuscate from 'react-obfuscate';
import Button from 'stillnovel/components/UI/Button';
import Group from 'stillnovel/components/UI/Group';
import Loader from 'stillnovel/components/UI/Loader';
import Page from 'stillnovel/components/UI/Page';
import Section, { Head } from 'stillnovel/components/UI/Section';
import Text from 'stillnovel/components/UI/Text';
import Wrapper from 'stillnovel/components/UI/Wrapper';

import Header from '../Checkout/Header';
import CartItem from '../Checkout/Cart/CartItems/CartItem';

import styles from './OrderOverview.scss';
import { loadingStates } from './OrderOverview.container';

const shipped = status => {
    const trackingId = status.event?.metadata?.trackingId ?? '';
    const shippingTrackingURL = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingId}&cntry_code=us&locale=en_US`;
    return null;
    // return (
    //     <Text tag="li" className={styles['list-item']} theme="button">
    //         <div>Status: Shipped (TN:{trackingId})</div>
    //         <Button
    //             className={styles['tracking-button']}
    //             href={shippingTrackingURL}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             block
    //             xsmall
    //         >
    //             Track This Order
    //         </Button>
    //     </Text>
    // );
};

function renderStatus(status) {
    switch (status.id) {
        case 10:
            return shipped(status);
    }
    return null;
}

const OrderOverview = props => {
    const { slug } = useParams();

    const isCongrats = slug === 'congrats';

    if (props.loadState === loadingStates.LOADING) {
        return (
            <Page>
                <Wrapper>
                    <Loader fullscreen text="Fetching order..." />
                </Wrapper>
            </Page>
        );
    }

    return (
        <Page>
            <Helmet>
                <title>Order Overview</title>
            </Helmet>
            <Wrapper>
                <Header
                    icon={!isCongrats ? 'complete' : null}
                    heading={isCongrats ? 'Congratulations' : 'Order Receipt'}
                />
                <div className={styles.root}>
                    {props.loadState === loadingStates.ERROR ? (
                        <Section className={styles['not-found']}>
                            <Text theme="title" tag="h3">
                                Unfortunately, we cannot find this order.
                            </Text>
                            <Text tag="p">
                                If you need assistance or believe you&#x27;ve
                                received this message in error, please contact
                                us at{' '}
                                <Obfuscate email="support@stillnovel.com" />.
                            </Text>
                        </Section>
                    ) : (
                        <React.Fragment>
                            <Section>
                                <Head
                                    cells={[
                                        {
                                            label: isCongrats
                                                ? 'Order Summary'
                                                : 'Details',
                                            colSpan: 8,
                                        },
                                    ]}
                                />
                                <Group>
                                    <ul className={styles.list}>
                                        <Text
                                            tag="li"
                                            className={styles['list-item']}
                                            theme="button"
                                        >
                                            Ref: #{props.orderId}
                                        </Text>
                                        <Text
                                            tag="li"
                                            className={styles['list-item']}
                                            theme="button"
                                        >
                                            Status:{' '}
                                            {props.status && props.status.title}
                                        </Text>
                                        <Text
                                            tag="li"
                                            className={styles['list-item']}
                                            theme="button"
                                        >
                                            Total: {props.grandTotal}
                                        </Text>
                                        {props.giftCard && (
                                            <Text
                                                tag="li"
                                                className={styles['list-item']}
                                                theme="button"
                                            >
                                                {props.amountGiftCardApplied},{' '}
                                                Gift Card {props.giftCard.code}
                                            </Text>
                                        )}
                                        <Text
                                            tag="li"
                                            className={styles['list-item']}
                                            theme="button"
                                        >
                                            {props.totalDue}
                                            {props.cardType &&
                                            props.cardLastFour
                                                ? `, ${props.cardType} ${props.cardLastFour}`
                                                : ''}
                                        </Text>
                                        <Text
                                            tag="li"
                                            className={styles['list-item']}
                                            theme="button"
                                        >
                                            Shipping: {props.shippingOption}{' '}
                                        </Text>
                                        {renderStatus(props.status)}
                                    </ul>
                                    <ul
                                        className={cx(
                                            styles.list,
                                            styles.actions
                                        )}
                                    >
                                        {props.status && props.status.id === 1 && (
                                            <Text
                                                tag="li"
                                                className={styles['list-item']}
                                                theme="form-dropdown"
                                            >
                                                <Button
                                                    href={`mailto:orders@stillnovel.com?subject=I would like to cancel my order (#${props.orderId})`}
                                                >
                                                    Cancel this order
                                                </Button>
                                                <br />
                                                <Text theme="caption">
                                                    (Time left{' '}
                                                    <Text isMonospace>
                                                        <Countdown
                                                            endDate={
                                                                props.endDate
                                                            }
                                                        />
                                                    </Text>
                                                    )
                                                </Text>
                                            </Text>
                                        )}
                                        <Text
                                            tag="li"
                                            className={styles['list-item']}
                                            theme="form-dropdown"
                                        >
                                            <Button to="/">My account</Button>
                                        </Text>
                                        <Text
                                            tag="li"
                                            className={styles['list-item']}
                                            theme="form-dropdown"
                                        >
                                            <Button
                                                href="/faq/what-is-your-returnexchange-policy"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Returns policy
                                            </Button>
                                        </Text>
                                    </ul>
                                </Group>
                            </Section>
                            <Section>
                                <Head
                                    cells={[
                                        { label: 'Item', colSpan: 4 },
                                        { label: 'Name', colSpan: 2 },
                                        { label: 'Details', colSpan: 2 },
                                    ]}
                                />
                                <ul className={styles['cart-items']}>
                                    {props.items.map((item, i) => {
                                        return (
                                            <CartItem
                                                readOnly
                                                key={i}
                                                {...{
                                                    amount: item.priceBaseAmount,
                                                    attributes: '',
                                                    id: item.id,
                                                    project: {
                                                        ...item.project,
                                                        user: props.user,
                                                    },
                                                    amounts: {
                                                        price: {
                                                            baseAmount:
                                                                item.priceBaseAmount,
                                                            discountedAmount:
                                                                item.priceDiscountedAmount,
                                                            matchedRule: false,
                                                        },
                                                        shipping: {
                                                            baseAmount:
                                                                item.shippingBaseAmount,
                                                            discountedAmount:
                                                                item.shippingDiscountedAmount,
                                                            matchedRule: false,
                                                        },
                                                    },
                                                    qty: item.qty,
                                                    title:
                                                        item.project.title ||
                                                        'untitled',
                                                }}
                                            />
                                        );
                                    })}
                                </ul>
                            </Section>
                        </React.Fragment>
                    )}
                    <Group tag="section" className={styles['button-wrapper']}>
                        <Button
                            small
                            block
                            outline
                            to="/"
                            className={styles.button}
                        >
                            Return to Projects
                        </Button>
                        <Button
                            small
                            block
                            outline
                            to="/orders"
                            className={styles.button}
                        >
                            View Past Orders
                        </Button>
                    </Group>
                </div>
            </Wrapper>
        </Page>
    );
};

OrderOverview.propTypes = {
    amountGiftCardApplied: PropTypes.any,
    cardLastFour: PropTypes.any,
    cardType: PropTypes.any,
    endDate: PropTypes.any,
    giftCard: PropTypes.shape({
        code: PropTypes.any,
    }),
    grandTotal: PropTypes.any,
    items: PropTypes.shape({
        map: PropTypes.func,
    }),
    loadState: PropTypes.any,
    orderId: PropTypes.string,
    shippingOption: PropTypes.string,
    status: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.any,
    }),
    totalDue: PropTypes.any,
    totalPrice: PropTypes.number,
    user: PropTypes.any,
};

OrderOverview.defaultProps = {
    orderId: '',
    shippingOption: 'Ground',
    totalPrice: 0,
};

OrderOverview.autoload = false;

export default OrderOverview;
