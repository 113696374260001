import querystring from 'querystring';

import PropTypes from 'prop-types';
import React, { cloneElement, useState } from 'react';
import AuthOverlay from 'stillnovel/components/App/Auth/Overlay';
import Portal from 'stillnovel/components/UI/Portal';

const AuthTrigger = props => {
    // pre-open when registering facebook to confirm email
    const response = querystring.parse(location.search.substring(1));

    const [authVisible, setAuthVisibility] = useState(
        response?.status === 'register'
    );

    return (
        <>
            {cloneElement(props.children, {
                onClick: () => setAuthVisibility(!authVisible),
            })}
            <Portal>
                {authVisible && (
                    <AuthOverlay handleClose={() => setAuthVisibility(false)} />
                )}
            </Portal>
        </>
    );
};

AuthTrigger.propTypes = {
    children: PropTypes.any,
};

export default AuthTrigger;
