import d from 'debug';
import { put } from 'redux-saga/effects';
import { updateProduct } from 'stillnovel/store/project/actions';
import {
    scopedMetaUpdateHandler,
    handleImageMeta,
} from 'stillnovel/store/project/shared';

//const debug = d('App:Gallery-Print');

// function* handleProductChange(action) {
//     debug(`Change Product ${action.payload.value}`);
//     yield put(updateProduct(action.payload.value));
// }

const derivedHandlers = {
    // code: handleProductChange,
    imageMeta: handleImageMeta,
};

export default scopedMetaUpdateHandler(
    /^simple-framed-print-([a-zA-Z0-9-]+)$/,
    derivedHandlers
);
