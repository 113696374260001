import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { useParams } from 'react-router';
import { resetPassword, logout, states } from 'stillnovel/store/auth/actions';
import reduxForm from 'redux-form/es/reduxForm';
import getFormValues from 'redux-form/es/getFormValues';
import Field from 'redux-form/es/Field';
import Button from 'stillnovel/components/UI/Button';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import Section from 'stillnovel/components/UI/Section';
import Text from 'stillnovel/components/UI/Text';

import styles from './Auth.scss';

const required = value =>
    value || typeof value === 'number' ? undefined : 'Required';

const minLength = min => value =>
    value && value.length < min
        ? `Must be ${min} characters or more`
        : undefined;

const minLength6 = minLength(6);

const matchPasswords = (value, values) => {
    if (values.password && values.passwordc !== values.password) {
        return 'Passwords must match';
    }
    return false;
};

const Verification = ({ valid, pristine, submitting }) => {
    const dispatch = useDispatch();
    const { verificationToken } = useParams();
    const values = useSelector(state => getFormValues('verify')(state));
    const hasError = useSelector(
        state => state.auth.state === states.ACCOUNT_VERIFICATION_ERROR
    );
    const authError = useSelector(state => state.auth.error);

    useMount(() => {
        dispatch(logout());
    });

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(
            resetPassword({
                verificationToken,
                ...values,
            })
        );
    };

    return (
        <Fragment>
            <Text tag="h3" theme="form-header-alt">
                Reset Password
            </Text>
            {hasError && (
                <Text tag="p" isError>
                    {authError}
                </Text>
            )}
            <Section className={styles['auth-verification']}>
                <Form
                    onSubmit={handleSubmit}
                    className={styles['auth-verification-form']}
                >
                    <div className={styles['row-verification']}>
                        <Text
                            tag="label"
                            theme="body1-alt"
                            htmlFor="password"
                            className={styles.label}
                        >
                            New password (required)
                        </Text>
                        <Field
                            component={FieldInput}
                            className={styles['auth-verification-input']}
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            validate={[required, minLength6]}
                            autoComplete="new-password"
                            showErrorText
                        />
                        <Field
                            component={FieldInput}
                            className={styles['auth-verification-input']}
                            type="password"
                            name="passwordc"
                            placeholder="Confirm password"
                            validate={[required, matchPasswords]}
                            autoComplete="new-password"
                            showErrorText
                        />
                    </div>
                    <div className={styles['row-verification']}>
                        <Button
                            block
                            type="submit"
                            disabled={!valid || pristine || submitting}
                        >
                            {submitting ? 'Sending...' : 'Submit'}
                        </Button>
                    </div>
                </Form>
            </Section>
        </Fragment>
    );
};

Verification.propTypes = {
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'verify',
    touchOnBlur: true,
})(Verification);
