import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Page from 'stillnovel/components/UI/Page';
import Wrapper from 'stillnovel/components/UI/Wrapper';

import Cart from './Cart';
import Header from './Header';
import styles from './Checkout.scss';

function Checkout() {
    return (
        <Page>
            <Wrapper>
                <Helmet>
                    <title>Checkout</title>
                </Helmet>
                <Header hasBanner={false} />
                <div className={styles.content}>
                    <Cart />
                </div>
            </Wrapper>
        </Page>
    );
}

export default memo(Checkout);
