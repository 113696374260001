import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './SvgIcon.scss';
import icons from './icons';

const SvgIcon = ({ className, iconType, iconSrc, tag }) => {
    const TagName = tag;
    const src = iconSrc || icons;
    return (
        <TagName
            className={cx(styles.root, className)}
            dangerouslySetInnerHTML={{ __html: src[iconType] }}
        />
    );
};

SvgIcon.defaultProps = {
    className: null,
    iconSrc: null,
    tag: 'span',
};

SvgIcon.propTypes = {
    className: PropTypes.string,
    iconSrc: PropTypes.object,
    iconType: PropTypes.string.isRequired,
    tag: PropTypes.string,
};

export default SvgIcon;
