import ArtPrint from './Builders/ArtPrint';

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';

const defaultArtPrintAttributes = category => project => {
    return [
        {
            key: 'Design',
            value: `${category} - ${capitalize(project.metadata.style)}`,
        },
        project.metadata?.designNote && {
            key: 'Note',
            value: project.metadata?.designNote,
        },
    ];
};

const defaultArtPrintDashboardTitle = category => project =>
    `${capitalize(project.metadata.style)} ${category} Art Print - 16" x 20"`;

const simplePrintSizes = [
    '10x10',
    '11x15',
    '15x11',
    '16x20',
    '20x16',
    '20x24',
    '24x20',
];

const vintageCommon = {
    photoTargetWidth: 967,
    photoTargetHeight: 1317,
};

const modernCommon = {
    photoTargetWidth: 1600,
    photoTargetHeight: 2000,
};

export default {
    'birth-stat': {
        title: 'Birth Stat',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `art-print-16x20`,
        component: ArtPrint,
        shopifyLineItemAttributes: defaultArtPrintAttributes('Birth Stat'),
        dashboardTitle: defaultArtPrintDashboardTitle('Birth Stat'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project => {
            const style = project?.metadata?.style?.toLowerCase();
            return `${project.builderHandle}-${style || 'modern'}`;
        },
    },
    travel: {
        title: 'Travel',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `art-print-16x20`,
        component: ArtPrint,
        shopifyLineItemAttributes: defaultArtPrintAttributes('Travel'),
        dashboardTitle: defaultArtPrintDashboardTitle('Travel'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    wedding: {
        title: 'Wedding',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'couple', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `art-print-16x20`,
        component: ArtPrint,
        shopifyLineItemAttributes: defaultArtPrintAttributes('Wedding'),
        dashboardTitle: defaultArtPrintDashboardTitle('Wedding'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    couples: {
        title: 'Couples',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'couple', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `art-print-16x20`,
        component: ArtPrint,
        shopifyLineItemAttributes: defaultArtPrintAttributes('Couples'),
        dashboardTitle: defaultArtPrintDashboardTitle('Couples'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    engagement: {
        title: 'Engagement',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'couple', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `art-print-16x20`,
        component: ArtPrint,
        shopifyLineItemAttributes: defaultArtPrintAttributes('Engagement'),
        dashboardTitle: defaultArtPrintDashboardTitle('Engagement'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    'all-about-you': {
        title: 'All About You',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'details2', 'stats', 'review', 'frame'],
        shopifyProductHandle: `art-print-16x20`,
        component: ArtPrint,
        shopifyLineItemAttributes: defaultArtPrintAttributes('All About You'),
        dashboardTitle: defaultArtPrintDashboardTitle('All About You'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    parent: {
        title: 'Parent',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'details2', 'review', 'frame'],
        shopifyProductHandle: `art-print-16x20`,
        component: ArtPrint,
        shopifyLineItemAttributes: defaultArtPrintAttributes('Parent'),
        dashboardTitle: defaultArtPrintDashboardTitle('Parent'),
        projectDefaults: { style: 'modern', subject: 'mom' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    pet: {
        title: 'Pet',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'details2', 'review', 'frame'],
        shopifyProductHandle: `art-print-16x20`,
        component: ArtPrint,
        shopifyLineItemAttributes: defaultArtPrintAttributes('Pet'),
        dashboardTitle: defaultArtPrintDashboardTitle('Pet'),
        projectDefaults: { style: 'modern', pet: 'DOG' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    ...simplePrintSizes.reduce((o, size) => {
        const [w, h] = size.split('x');
        o[`simple-framed-print-${size}`] = {
            parentCategory: 'Custom Framed Print',
            buildSteps: ['', 'review', 'frame'],
            shopifyProductHandle: `simple-framed-print-${size}`,
            component: ArtPrint,
            shopifyLineItemAttributes: defaultArtPrintAttributes(
                `Custom Framed Print ${w}" x ${h}"`
            ),
            dashboardTitle: project =>
                `${capitalize(
                    project.metadata.style
                )} Custom Framed Print - ${w}" x ${h}"`,

            projectDefaults: { style: 'modern' },
            printWidth: parseInt(w),
            printHeight: parseInt(h),
            previewLoaderHandle: project => `${project.builderHandle}`,
        };
        return o;
    }, {}),
};
