import { allowedCharacters } from 'stillnovel/constants';

export const allowedSentryDomains = [
    /https?:\/\/((my.dev|my)\.)?stillnovel\.com/,
    /https?:\/\/((.*)\.)?vercel\.app/,
];

export function getDisplayName(component) {
    return (
        component.displayName ||
        component.name ||
        component.type.displayName ||
        'Component'
    );
}

export function zeroFill(number, width) {
    width = width - number.toString().length;
    if (width > 0) {
        return (
            new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
        );
    }
    return number + ''; // always return a PropTypes.string
}

export function getCurrencySymbol(type) {
    const symbols = {
        usd: '$',
        default: '',
    };
    return symbols[type] || symbols.default;
}

export function getSubTotal(cartItems = []) {
    if (cartItems.length === 0) {
        return 0;
    }
    return cartItems
        .map(item => item.project.product.price * item.qty)
        .reduce((a, b) => a + b)
        .toFixed(2);
}

export function emailMask(email) {
    let emailAddress,
        emailLength,
        emailValue,
        emailMasked,
        i,
        emailPrint = '';

    emailAddress = email.split('@');
    emailLength = emailAddress[0].length;
    emailValue = emailLength / 2;
    emailMasked = emailLength - emailValue;
    for (i = 0; i < emailValue; i++) {
        emailPrint += emailAddress[0][i];
    }
    for (i = 0; i < emailMasked; i++) {
        emailPrint += '*';
    }
    return emailPrint + '@' + emailAddress[1];
}

export function isTouch() {
    return (
        !!(
            typeof window !== 'undefined' &&
            ('ontouchstart' in window ||
                (window.DocumentTouch &&
                    typeof document !== 'undefined' &&
                    document instanceof window.DocumentTouch))
        ) ||
        !!(
            typeof navigator !== 'undefined' &&
            (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
        )
    );
}

export function getNaturalImageDimensions(path = '') {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () =>
            resolve({
                width: img.naturalWidth,
                height: img.naturalHeight,
            });
        img.onerror = () =>
            reject('Error loading image in `getNaturalImageDimensions`');
        img.src = path;
    }).catch(err => {
        throw err;
    });
}

export function getFileExtensionFromPath(path) {
    return path.slice(((path.lastIndexOf('.') - 1) >>> 0) + 2);
}

export function toUSDFloat(int) {
    return int / 100;
}

export function stripQuotes(value = '') {
    return value.replace(/["]+/g, '');
}

export function stripWhitespace(value = '') {
    return value.trim();
}

export function stripEndPeriod(value = '') {
    return value.replace(/[.$]+/g, '');
}

export function sanitizeString(value = '') {
    if (typeof value !== 'string') {
        const WARNING_TXT = `Parameter warning: sanitizeString() expects a string. Current parameter type: ${typeof value}. Skipping sanitation.`;
        console.warn(WARNING_TXT);
        return value;
    }
    const chars = [...value];
    const sanitizedChars = chars.filter(letter => {
        return letter.match(allowedCharacters) ? letter : null;
    });
    return sanitizedChars.join('');
}
