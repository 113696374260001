import 'regenerator-runtime/runtime';
import 'requestidlecallback';
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import getConfig from 'core/config';
import SN from 'stillnovel/services';
import { SWRConfig } from 'swr';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
} from '@apollo/client';

import App from './App';
import ErrorBoundary from './ErrorBoundary';

const apiFetcher = ({ url, ...options }) => {
    return SN.api[options.method || 'get'](url, options).then(res => res.data);
};

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new createHttpLink({
        uri: `https://${getConfig(
            'shopifyStorefrontDomain'
        )}/api/2022-04/graphql`,
        headers: {
            'X-Shopify-Storefront-Access-Token': getConfig(
                'shopifyStorefrontAccessToken'
            ),
            Accept: 'application/json',
        },
    }),
});

const Root = props => {
    return (
        <ErrorBoundary>
            <ApolloProvider client={client}>
                <SWRConfig
                    value={{
                        fetcher: apiFetcher,
                    }}
                >
                    <Provider store={props.store}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </Provider>
                </SWRConfig>
            </ApolloProvider>
        </ErrorBoundary>
    );
};

Root.propTypes = {
    store: PropTypes.object.isRequired,
};

export default Root;
