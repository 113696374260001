import querystring from 'querystring';

import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SN from 'stillnovel/services';
import { authenticated, login } from 'stillnovel/store/auth/actions';
import { emailMask } from 'stillnovel/utils';
import reduxForm from 'redux-form/es/reduxForm';
import formValueSelector from 'redux-form/es/formValueSelector';
import actions from 'redux-form/es/actions';
import Button from 'stillnovel/components/UI/Button';
import Field from 'redux-form/es/Field';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import Loader from 'stillnovel/components/UI/Loader';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import Text from 'stillnovel/components/UI/Text';
import keymirror from 'keymirror';
import getConfig from 'core/config';
import { FacebookUserNotRegisteredException } from 'core/services/exceptions';
import d from 'debug';

import styles from './Auth.scss';

const Buffer = require('buffer/').Buffer;

const debug = d('App:Auth');

const viewStates = keymirror({
    DEFAULT: null,
    REGISTER_FACEBOOK: null,
    REGISTER: null,
    PASSWORD: null,
    UNVERIFIED: null,
    VERIFICATION_EMAIL_SENT: null,
    ACCOUNT_CREATED: null,
    PASSWORD_RESET: null,
});

const loadingStates = keymirror({
    FACEBOOK: null,
    EMAIL: null,
    PASSWORD: null,
    RESEND_VERIFICATION_BUTTON: null,
    PASSWORD_RESET: null,
});

const authSelector = formValueSelector('auth');

const required = value => (value ? undefined : 'Required');

const Auth = ({ handleClose }) => {
    const [state, setState] = useState({
        error: null,
        view: viewStates.DEFAULT,
        fbAccessToken: '',
        fbEmail: '',
        isLoading: false,
    });

    const dispatch = useDispatch();

    const email = useSelector(state => authSelector(state, 'email'));
    const emailVerify = useSelector(state =>
        authSelector(state, 'emailVerify')
    );
    const password = useSelector(state => authSelector(state, 'password'));
    const authState = useSelector(state => state.auth.state);
    const user = useSelector(state => state.auth.user);
    const userRole = user?.role;

    const navigate = useNavigate();
    const location = useLocation();

    const setEmail = email => {
        const action = actions.change('auth', 'email', email);
        dispatch(action);
    };

    // const logout = () => {
    //     const action = actions.change('auth', 'email', email);
    //     dispatch(action);
    // };

    const handleExit = () => {
        // if overlay handleClose or if page navigate /
        handleClose ? handleClose() : navigate('/');
    };

    useEffect(() => {
        if (
            authState === 'AUTH/LOGGED_IN' &&
            userRole !== 'guest' &&
            state.view !== viewStates.ACCOUNT_CREATED
        ) {
            handleExit();
        }
    }, [authState, navigate, userRole]);

    const resendVerificationEmail = email =>
        SN.auth.resendVerificationEmail(email);

    const getEmailStatus = email => SN.auth.status(email);

    const authUser = useCallback(
        async (email, password) => {
            const user = await SN.auth.authenticate(email, password);
            dispatch(authenticated(user));
        },
        [dispatch]
    );

    const registerUser = useCallback(
        async email => {
            const user = await SN.auth.register(email);
            dispatch(authenticated(user));
        },
        [dispatch]
    );

    const registerFacebookUser = useCallback(
        async (email, token) => {
            const user = await SN.auth.registerFacebook(email, token);
            dispatch(authenticated(user));
        },
        [dispatch]
    );

    const handleFacebookRedirectResponse = useCallback(async () => {
        setState({ isLoading: loadingStates.FACEBOOK });
        // Try and Login
        const { user } = await SN.auth.getSession();
        dispatch(authenticated(user));
    }, [dispatch, setState]);

    useEffect(() => {
        const response = querystring.parse(location.search.substring(1));
        if (response.status === 'register' && response.state) {
            const register = JSON.parse(atob(response.state));
            setEmail(register.email);
            setState({
                fbEmail: register.email,
                fbAccessToken: register.accessToken,
                view: viewStates.REGISTER_FACEBOOK,
                isLoading: false,
            });
        }

        if (response.status === 'success') {
            //clear code
            navigate({
                ...location,
                search: undefined,
            });
            handleFacebookRedirectResponse();
        }
    }, []);

    const handleClickFacebook = async () => {
        const query = querystring.parse(location.search.substring(1));

        let state = {
            fbAppId: getConfig('fbAppId'),
            stripeKey: getConfig('stripeKey'),
            clientUrl: getConfig('clientUrl'),
            pathname: window.location.pathname,
            checkoutUrl: query?.checkout_url,
        };

        if (query?.checkout_url) {
            state = {
                fbAppId: getConfig('fbAppId'),
                stripeKey: getConfig('stripeKey'),
                clientUrl: 'https://shop.stillnovel.com',
                pathname: query?.checkout_url.replace(
                    /^(https:\/\/shop\.stillnovel\.com|https:\/\/stillnovel\.myshopify\.com|https:\/\/stillnovel-dev\.myshopify\.com)/,
                    ''
                ),
            };
        }

        setState({ ...state, isLoading: loadingStates.FACEBOOK });

        window.location = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${getConfig(
            'fbAppId'
        )}&redirect_uri=${getConfig(
            'apiEndpoint'
        )}v2/auth/facebook-redirect&scope=email,public_profile&state=${encodeURIComponent(
            Buffer.from(JSON.stringify(state)).toString('base64')
        )}`;
    };

    const handleChangeEmail = () => {
        setState({
            ...state,
            view: viewStates.DEFAULT,
        });
    };

    const handleClickResendVerificationEmail = async () => {
        setState({ isLoading: loadingStates.RESEND_VERIFICATION_BUTTON });
        await resendVerificationEmail(email);
        setState({
            ...state,
            isLoading: false,
            view: viewStates.VERIFICATION_EMAIL_SENT,
        });
    };

    const handleSubmitPasswordLogin = async event => {
        event.preventDefault();
        setState({ ...state, isLoading: loadingStates.PASSWORD, error: '' });
        try {
            await authUser(email, password);
            handleExit();
        } catch (error) {
            setState({
                ...state,
                isLoading: false,
                error: 'Invalid Password',
            });
        }
    };

    const handleSubmitRegister = async event => {
        event.preventDefault();
        setState({ ...state, isLoading: loadingStates.EMAIL });
        debug(`Register User`);

        await registerUser(email);
        setState({
            ...state,
            view: viewStates.ACCOUNT_CREATED,
            isLoading: false,
        });
    };

    const handleSubmitFacebookRegister = async event => {
        event.preventDefault();
        setState({ isLoading: loadingStates.EMAIL });

        await registerFacebookUser(email, state.fbAccessToken);

        if (email !== state.fbEmail) {
            setState({
                ...state,
                view: viewStates.ACCOUNT_CREATED,
                isLoading: false,
            });
        } else {
            setState({ ...state, isLoading: false });
        }
    };

    const handleSubmitEmail = async event => {
        event.preventDefault();
        setState({ ...state, isLoading: loadingStates.EMAIL, error: '' });
        const emailStatus = await getEmailStatus(email);

        switch (emailStatus) {
            case 'UNVERIFIED':
                debug(`Unverified Email`);
                setState({
                    ...state,

                    view: viewStates.UNVERIFIED,
                    isLoading: false,
                });
                break;
            case 'VERIFIED_FACEBOOK':
                debug(`Registered Facebook`);
                handleClickFacebook();
                break;
            case 'VERIFIED':
                debug(`Registered Email`);
                setState({
                    ...state,

                    view: viewStates.PASSWORD,
                    isLoading: false,
                });
                break;
            case 'UNREGISTERED':
                setState({
                    ...state,

                    view: viewStates.REGISTER,
                    isLoading: false,
                });
                break;
        }
    };

    const renderLoader = type => {
        return state.isLoading === type ? (
            <div className={styles['auth-loader']}>
                <Loader theme="small" />
            </div>
        ) : null;
    };

    const renderDefault = () => {
        return (
            <>
                <Text tag="h3" theme="form-header-alt">
                    Get Started
                </Text>
                <section>
                    <div className={styles['auth-social']}>
                        <Button
                            small
                            block
                            theme="outline"
                            onClick={handleClickFacebook}
                            className={styles['button-facebook']}
                        >
                            <SvgIcon
                                iconType="facebookLogo"
                                className={styles['button-icon']}
                            />
                            <span className={styles['button-label']}>
                                Sign in with FaceBook
                            </span>
                        </Button>
                        {renderLoader(loadingStates.FACEBOOK)}
                    </div>
                    <Text tag="div" className={styles.separator} theme="body2">
                        or
                    </Text>
                    <Form
                        onSubmit={handleSubmitEmail}
                        className={styles['auth-traditional']}
                    >
                        <div className={styles.row}>
                            <Field
                                component={FieldInput}
                                className={styles['auth-input']}
                                type="email"
                                name="email"
                                validate={required}
                                placeholder="Email address"
                                autoComplete="email"
                                required
                            />
                            <Button
                                className={styles['auth-submit']}
                                type="submit"
                            >
                                <SvgIcon iconType="arrowRight" />
                            </Button>
                            {renderLoader(loadingStates.EMAIL)}
                        </div>
                    </Form>
                </section>
                <Text tag="p" className={styles['auth-legal']} theme="caption">
                    If you click &quot;Sign in with Facebook&quot; and are not a
                    Still Novel user, you will be registered and you agree to
                    Still Novel&apos;s{' '}
                    <Button href="/terms" target="_blank">
                        Terms &amp; Conditions
                    </Button>{' '}
                    and{' '}
                    <Button href="/privacy" target="_blank">
                        Privacy Policy
                    </Button>
                    .
                </Text>
            </>
        );
    };

    const renderFacebookConfirmEmail = () => {
        return (
            <>
                <Text tag="h3" theme="form-header-alt">
                    Confirm Your Email
                </Text>
                <section>
                    <Form
                        onSubmit={handleSubmitFacebookRegister}
                        className={styles['auth-traditional']}
                    >
                        <div className={styles.row}>
                            <Field
                                className={styles['auth-input']}
                                component={FieldInput}
                                type="email"
                                name="email"
                                validate={required}
                                placeholder="Email address"
                                autoComplete="email"
                                required
                            />
                            <Button
                                className={styles['auth-submit']}
                                type="submit"
                            >
                                <SvgIcon iconType="arrowRight" />
                            </Button>
                            {renderLoader(loadingStates.EMAIL)}
                        </div>
                    </Form>
                </section>
                <Text tag="p" className={styles['auth-legal']} theme="caption">
                    By confirming and submitting, you agree to Still
                    Novel&apos;s{' '}
                    <Button href="/terms" target="_blank">
                        Terms &amp; Conditions
                    </Button>{' '}
                    and{' '}
                    <Button href="/privacy" target="_blank">
                        Privacy Policy
                    </Button>
                    .
                </Text>
            </>
        );
    };

    const renderConfirmEmail = () => {
        return (
            <>
                <Text tag="h3" theme="form-header-alt">
                    New Account
                </Text>
                <section>
                    <Form
                        onSubmit={handleSubmitRegister}
                        className={styles['auth-traditional']}
                    >
                        <div className={styles.row}>
                            <Text tag="p" theme="body1">
                                {emailMask(email.toLowerCase())} (
                                <Button onClick={handleChangeEmail}>
                                    change email
                                </Button>
                                )
                            </Text>
                        </div>
                        <div className={styles.row}>
                            <Field
                                className={styles['auth-input']}
                                component={FieldInput}
                                type="email"
                                name="emailVerify"
                                validate={required}
                                placeholder="Confirm email"
                                onKeyPress={handleKeyPressConfirm}
                                autoComplete="email"
                                required
                            />
                            <Button
                                className={styles['auth-submit']}
                                disabled={email !== emailVerify}
                                type="submit"
                            >
                                <SvgIcon iconType="arrowRight" />
                            </Button>
                            {renderLoader(loadingStates.EMAIL)}
                        </div>
                        {state.error && (
                            <Text tag="p" theme="body1" isError>
                                {state.error}
                            </Text>
                        )}
                    </Form>
                </section>
                <Text tag="p" className={styles['auth-legal']} theme="caption">
                    By confirming and submitting, you agree to Still
                    Novel&apos;s{' '}
                    <Button href="/terms" target="_blank">
                        Terms &amp; Conditions
                    </Button>{' '}
                    and{' '}
                    <Button href="/privacy" target="_blank">
                        Privacy Policy
                    </Button>
                    .
                </Text>
            </>
        );
    };

    const handleKeyPressConfirm = e => {
        // Because this particular <form> wasn't initially part
        // of the <body> on initial render, Chrome (and maybe others)
        // kick the user back to the default <form>
        // This catches a keypress on the 'enter' key and submits the form
        setState({ ...state, error: '' });

        if (e.key === 'Enter') {
            e.preventDefault();
            if (email === emailVerify) {
                handleSubmitRegister(e);
            } else {
                setState({ ...state, error: 'Emails must match' });
            }
        }
    };

    const handleKeyPressPassword = e => {
        // Because this particular <form> wasn't initially part
        // of the <body> on initial render, Chrome (and maybe others)
        // kick the user back to the default <form>
        // This catches a keypress on the 'enter' key and submits the form
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmitPasswordLogin(e);
        }
    };

    const handleResetPassword = async event => {
        event.preventDefault();
        setState({
            ...state,

            isLoading: loadingStates.PASSWORD_RESET,
        });
        await SN.auth.resetPasswordRequest(email);
        setState({
            ...state,

            isLoading: false,
            view: viewStates.PASSWORD_RESET,
        });
    };

    const renderPassword = () => {
        return (
            <>
                <Text tag="h3" theme="form-header-alt">
                    Sign In
                </Text>
                <section>
                    <Form
                        onSubmit={handleSubmitPasswordLogin}
                        className={styles['auth-traditional']}
                    >
                        <div className={styles.row}>
                            <Text tag="p" theme="body1">
                                {emailMask(email.toLowerCase())} (
                                <Button onClick={handleChangeEmail}>
                                    change email
                                </Button>
                                )
                            </Text>
                        </div>
                        <div className={styles.row}>
                            <Field
                                component={FieldInput}
                                type="password"
                                name="password"
                                validate={required}
                                placeholder="Password"
                                autoComplete="password"
                                onKeyPress={handleKeyPressPassword}
                                autoFocus
                                required
                            />
                            <Button
                                className={styles['auth-submit']}
                                type="submit"
                            >
                                <SvgIcon iconType="arrowRight" />
                            </Button>
                            {renderLoader(loadingStates.PASSWORD)}
                        </div>
                        <Button
                            className={styles['forgot-password']}
                            onClick={handleResetPassword}
                            theme="body1-alt"
                        >
                            Forgot your password?
                        </Button>
                        {renderLoader(loadingStates.PASSWORD_RESET)}
                        {state.error && (
                            <Text tag="p" theme="body1" isError>
                                {state.error}
                            </Text>
                        )}
                    </Form>
                </section>
            </>
        );
    };

    const renderUnverified = () => {
        return (
            <>
                <Text tag="h3" theme="form-header-alt">
                    Verification Required
                </Text>
                <Text tag="p" theme="body1">
                    Check your inbox to confirm your email address and finalize
                    registration.
                </Text>
                <div className={styles.row}>
                    <Button
                        small
                        block
                        theme="outline"
                        onClick={handleClickResendVerificationEmail}
                    >
                        Re-send Email
                    </Button>
                    {renderLoader(loadingStates.RESEND_VERIFICATION_BUTTON)}
                </div>
            </>
        );
    };

    const renderVerificationEmailSent = () => {
        return (
            <>
                <Text tag="h3" theme="form-header-alt">
                    Verification Email Sent
                </Text>
                <div className={styles.row}>
                    <Text tag="p">
                        Please check your inbox. We&#x27;ve re-sent an email to
                        verify your account.
                    </Text>
                </div>
            </>
        );
    };

    const renderPasswordReset = () => {
        return (
            <>
                <Text tag="h3" theme="form-header-alt">
                    Password Reset Email Sent
                </Text>
                <div className={styles.row}>
                    <Text tag="p">
                        Please check your inbox. We&#x27;ve sent an email to
                        reset your password.
                    </Text>
                </div>
            </>
        );
    };

    const renderAccountCreated = () => {
        return (
            <>
                <Text tag="h3" theme="form-header-alt">
                    Account Created
                </Text>
                <div className={styles.row}>
                    <Text tag="p">
                        Please check your inbox. We&#x27;ve sent an email to
                        verify your account.
                    </Text>
                </div>
                <Button small block theme="outline" onClick={handleExit}>
                    Continue to Still Novel
                </Button>
            </>
        );
    };

    switch (state.view) {
        case viewStates.REGISTER_FACEBOOK:
            return renderFacebookConfirmEmail();
        case viewStates.REGISTER:
            return renderConfirmEmail();
        case viewStates.PASSWORD:
            return renderPassword();
        case viewStates.PASSWORD_RESET:
            return renderPasswordReset();
        case viewStates.UNVERIFIED:
            return renderUnverified();
        case viewStates.VERIFICATION_EMAIL_SENT:
            return renderVerificationEmailSent();
        case viewStates.ACCOUNT_CREATED:
            return renderAccountCreated();
        default:
            return renderDefault();
    }
};

export default reduxForm({
    form: 'auth',
    updateUnauthedFields: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: true,
})(Auth);
