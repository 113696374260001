import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import ComboDatePicker from 'stillnovel/components/UI/Input/ComboDatePicker';
import Input from 'stillnovel/components/UI/Input';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import Text from 'stillnovel/components/UI/Text';

import styles from './Input.scss';

const isValidDate = date => {
    if (Object.prototype.toString.call(date) === '[object Date]') {
        // it is a date
        return !isNaN(date.getTime());
    }
    return false;
};

class TimeComboSelect extends Component {
    render() {
        const {
            handleChangeHours,
            handleChangeMinutes,
            hourValue,
            minuteValue,
            error,
            touched,
        } = this.props;

        return (
            <div className={styles['time-select']}>
                <Input
                    id="hours"
                    name="hours"
                    onChange={handleChangeHours}
                    defaultValue={hourValue}
                    type="select"
                    error={error}
                    touched={touched}
                >
                    <option disabled value="" key={'disabled'}>
                        HRS
                    </option>
                    <optgroup label="AM">
                        {Array(12)
                            .fill()
                            .map((_, i) => {
                                const hour = i;
                                const padHour = String(hour).padStart(2, '0');
                                return (
                                    <option value={padHour} key={i}>
                                        {hour === 0 ? '12' : hour}
                                    </option>
                                );
                            })}
                    </optgroup>
                    <optgroup label="PM">
                        {Array(12)
                            .fill()
                            .map((_, i) => {
                                const hour = i;
                                const padHour = String(hour + 12).padStart(
                                    2,
                                    '0'
                                );
                                return (
                                    <option value={padHour} key={i}>
                                        {hour === 0 ? '12' : hour}
                                    </option>
                                );
                            })}
                    </optgroup>
                </Input>
                <Input
                    id="minutes"
                    name="minutes"
                    onChange={handleChangeMinutes}
                    defaultValue={minuteValue}
                    type="select"
                    error={error}
                    touched={touched}
                >
                    <option disabled value="" key={'disabled'}>
                        MIN
                    </option>
                    {Array(60)
                        .fill()
                        .map((_, i) => {
                            const padMinute = String(i).padStart(2, '0');
                            return (
                                <option value={padMinute} key={i}>
                                    {padMinute}
                                </option>
                            );
                        })}
                </Input>
                <Text theme="form-title" className={styles.meridiem}>
                    {Number(hourValue) > 11 ? 'P.M.' : 'A.M.'}
                </Text>
                <div className={styles['time-select--clock']}>
                    <SvgIcon iconType="clock" />
                </div>
            </div>
        );
    }
}

TimeComboSelect.propTypes = {
    error: PropTypes.any,
    handleChangeHours: PropTypes.any,
    handleChangeMinutes: PropTypes.any,
    hourValue: PropTypes.any,
    minuteValue: PropTypes.any,
    touched: PropTypes.any,
};

class DateInput extends Component {
    constructor(props) {
        super(props);

        // Split up datetime into date and time

        const d = moment(props.input.value)
            .format()
            .split('-')
            .slice(0, -1)
            .join('-');

        const date = moment(d).toDate();

        const isValid = isValidDate(date);
        const timeArray = date.toTimeString().split(' ')[0].split(':');

        this.state = {
            date: isValid ? date : '',
            time: isValid ? `${timeArray[0]}:${timeArray[1]}` : '',
            hours: isValid ? timeArray[0] : '',
            minutes: isValid ? timeArray[1] : '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { date, time } = this.state;
        const { input, showTime } = this.props;

        const hasChanged = showTime
            ? (prevState.date !== date || prevState.time !== time) &&
              date &&
              time
            : prevState.date !== date && date;

        // Check if there's been changes
        if (hasChanged) {
            if (showTime ? date && time : date) {
                const splitTime = time.split(':');
                const hour = showTime ? parseInt(splitTime[0], 10) : 0;
                const min = showTime ? parseInt(splitTime[1], 10) : 0;

                // Combine time and date into single date
                const dateTime = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    hour,
                    min,
                    0
                );
                isValidDate(dateTime) && input.onChange(dateTime);
            }
        }
    }

    onChange = date => {
        this.setState({ date });
    };

    onChangeHours = e => {
        const { value } = e.target;
        this.setState({
            time: `${value}:${this.state.minutes}`,
            hours: value,
        });
    };

    onChangeMinutes = e => {
        const { value } = e.target;
        this.setState({
            time: `${this.state.hours}:${value}`,
            minutes: value,
        });
    };

    render() {
        const {
            input,
            showTime,
            showErrorText,
            meta: { touched, error },
            ...props
        } = this.props;

        return (
            <div className="dateTimePicker">
                <ComboDatePicker
                    {...input}
                    {...props}
                    error={error}
                    touched={touched}
                    order="mdy"
                    onChange={this.onChange}
                    date={isValidDate(this.state.date) ? this.state.date : null}
                    yearOrder="descending"
                    placeholder="YYYY,MM,DD"
                />
                {showTime && (
                    <TimeComboSelect
                        error={error}
                        touched={touched}
                        hourValue={this.state.hours}
                        minuteValue={this.state.minutes}
                        handleChangeHours={this.onChangeHours}
                        handleChangeMinutes={this.onChangeMinutes}
                    />
                )}
                {showErrorText && touched && error && (
                    <Text tag="p" isError>
                        {showErrorText && error}
                    </Text>
                )}
            </div>
        );
    }
}

DateInput.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        value: PropTypes.any,
    }),
    meta: PropTypes.shape({
        error: PropTypes.any,
        touched: PropTypes.bool,
    }),
    showErrorText: PropTypes.any,
    showTime: PropTypes.any,
};

export default DateInput;
