import get from 'lodash/get';
import cookie from 'js-cookie';

export default function getConfig(propPath, fallback) {
    // Public config ONLY!
    const config = {
        default: {
            apiEndpoint: 'https://localhost:4040/api/',
            clientUrl: 'https://localhost:3000',
            sentryEnvironment: process.env.ENV || 'unknown',
            fbAppId: 1390926834318835,
            debug: true,
            s3Bucket: 'dev.stillnovel.com',
            shopifyStorefrontAccessToken: '75ec6ebea2d27484e056385144320d16',
            shopifyStorefrontDomain: 'stillnovel-dev.myshopify.com',
            marcomBaseUrl: 'https://localhost:5000',
            cookieNameAuthToken: 'SN_local_token',
            cookieNameCheckout: 'SN_local_checkout',
            cookieDomain: 'localhost',
        },
        development: {
            apiEndpoint: 'https://api.dev.stillnovel.com/api/',
            clientUrl: process.env.CLIENT_URL
                ? `https://${process.env.CLIENT_URL}`
                : 'https://my.dev.stillnovel.com',
            sentryEnvironment: 'development',
            fbAppId: 325624514701900,
            debug: true,
            s3Bucket: 'dev.stillnovel.com',
            shopifyStorefrontAccessToken: '75ec6ebea2d27484e056385144320d16',
            shopifyStorefrontDomain: 'stillnovel-dev.myshopify.com',

            marcomBaseUrl:
                cookie.get('marcomBaseUrl') || 'https://dev.stillnovel.com',
            cookieNameAuthToken: 'SN_dev_token',
            cookieNameCheckout: 'SN_dev_checkout',
            cookieDomain: '.stillnovel.com',
        },
        staging: {
            apiEndpoint: 'https://api.stillnovel.com/api/',
            clientUrl: 'https://my-staging.stillnovel.com',
            sentryEnvironment: 'production',
            fbAppId: 121610628492364,
            debug: false,
            s3Bucket: 'my.stillnovel.com',
            shopifyStorefrontAccessToken: '2a594e9acb920215bc4d84f8570234ce',
            shopifyStorefrontDomain: 'shop.stillnovel.com',
            marcomBaseUrl: 'https://stillnovel.com',
            cookieNameAuthToken: 'token', // TEMP, switch SN_token
            cookieNameCheckout: 'SN_checkout',
            cookieDomain: '.stillnovel.com',
        },
        production: {
            apiEndpoint: 'https://api.stillnovel.com/api/',
            clientUrl: 'https://my.stillnovel.com',
            sentryEnvironment: 'production',
            fbAppId: 121610628492364,
            debug: false,
            s3Bucket: 'my.stillnovel.com',
            shopifyStorefrontAccessToken: '2a594e9acb920215bc4d84f8570234ce',
            shopifyStorefrontDomain: 'shop.stillnovel.com',
            marcomBaseUrl: 'https://stillnovel.com',
            cookieNameAuthToken: 'token',
            cookieNameCheckout: 'SN_checkout',
            cookieDomain: '.stillnovel.com',
        },
    };

    return get(
        {
            ...config.default,
            ...(config[process.env.ENV || 'default'] || {}),
        },
        propPath,
        fallback
    );
}
