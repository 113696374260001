import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';

import styles from './HGroup.scss';

const HGroup = ({ children }) => {
    return (
        <header className={styles.root}>
            {children}
            <SvgIcon iconType="arrowDown" className={styles['down-arrow']} />
        </header>
    );
};

HGroup.propTypes = {
    children: PropTypes.node.isRequired,
};

export default HGroup;
