import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Navigation from 'stillnovel/components/App/Navigation';
import Footer from 'stillnovel/components/App/Footer';
import { Subnav } from 'stillnovel/components/App/Account/Subnav';
import { useSelector } from 'react-redux';

const MainLayout = ({ children }) => {
    const [footerVisible, setFooterVisibility] = useState(false);
    const userRole = useSelector(state => state.auth?.user?.role);

    return (
        <Fragment>
            <Navigation />
            {children}
            {userRole && userRole !== 'guest' && (
                <Subnav forceShow={footerVisible} />
            )}
            <Footer footerInView={setFooterVisibility} />
        </Fragment>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object,
};

export default MainLayout;
