import {
    authenticated,
    confirmationRequired,
    login,
    loginFailure,
    unauthenticated,
    mfaRequired,
    newPasswordRequired,
    logout,
} from 'stillnovel/store/auth/actions';
import { InvalidRefreshTokenException } from 'core/services/exceptions';
import 'aws-sdk/lib/credentials/cognito_identity_credentials';
import SN from 'stillnovel/services';
import d from 'debug';

const debug = d('Auth');

/**
 * establishes a session with the user pool, and logs into the federated identity
 * pool using a token from the session
 * @param {object} user - the CognitoUser object
 * @return {Promise<object>} an action to be dispatched
 */
const performLogin = async () => {
    try {
        const session = await SN.auth.getSession();

        if (!session) {
            return logout();
        }

        if (session.role === 'guest') {
            return unauthenticated(session.user);
        }

        return login(session.user);
    } catch (error) {
        console.warn(error);
        switch (true) {
            case error instanceof InvalidRefreshTokenException:
                debug('InvalidRefreshTokenException');
                return loginFailure(error.user, 'Invalid Session');
            // default:
            //     return loginFailure(error.user, 'Incorrect email/password');
            default:
                throw error;
        }
    }
};

/**
 * establishes a session with the user pool, and logs into the federated identity
 * pool using a token from the session
 * @param {object} user - the CognitoUser object
 * @return {Promise<object>} an action to be dispatched
 */
export const performAnonymous = async () => {
    try {
        await SN.auth.initSessionGuestUser();

        return await performLogin();
    } catch (error) {
        console.log(error, 'performAnonymous');
        switch (true) {
            case error instanceof InvalidRefreshTokenException:
                debug('InvalidRefreshTokenException');
                return loginFailure(error.user, 'Invalid Session');
            default:
                return loginFailure(error.user, 'Incorrect email/password');
        }
    }
};

export const performLogout = () => {
    SN.auth.logout();
};

const authenticate = async (email, password) => {
    try {
        const user = await SN.auth.authenticate(email, password);
        return authenticated(user);
    } catch (error) {
        switch (error.code) {
            case 'UserNotConfirmedException':
                return confirmationRequired();
            case 'MFARequiredException':
                return mfaRequired(error.user);
            case 'NewPasswordRequiredException':
                return newPasswordRequired(error.user);
            default:
                return loginFailure(error.user, 'Incorrect email/password');
        }
    }
};

const authenticateFacebook = dispatch => {
    window.FB.getLoginStatus(async response => {
        if (response.status === 'connected') {
            const accessToken = response.authResponse.accessToken;
            // try {
            const user = await SN.auth.authenticateFacebook(accessToken);
            dispatch(authenticated(user));
        } else {
            // the user is logged in to Facebook,
            // but has not authenticated your app
            window.FB.login(loginResponse => {
                if (loginResponse.authResponse) {
                    debug(loginResponse);
                    debug('Welcome! Fetching your information.... ');
                } else {
                    debug('User canceled login or did not fully authorize.');
                }
            });
        }
    });
};

/**
 * Registers users thunk
 * @param {string} email - the email
 * @param {string} password - the password
 * @return {Promise<object>} a promise that resolves a redux action
 */
function registerUser(email) {
    return SN.auth
        .register(email)
        .then(user => {
            return authenticated(user);
        })
        .catch(error => {
            throw error;
        });
}

/**
 * sign up this user with their facebook account
 * @param {string} email - the email
 * @param {string} password - the password
 * @return {Promise<object>} a promise that resolves a redux action
 */

function registerFacebookUser(email, token) {
    return SN.auth
        .registerFacebook(email, token)
        .then(user => {
            return authenticated(user);
        })
        .catch(error => {
            throw error;
        });
}

/**
 * Registers users thunk
 * @param {string} email - the email
 * @param {string} password - the password
 * @return {Promise<object>} a promise that resolves a redux action
 */
function verifyEmail(uid, verificationToken) {
    return SN.auth.verify(uid, verificationToken);
}

export {
    authenticate,
    authenticateFacebook,
    performLogin,
    registerUser,
    registerFacebookUser,
    verifyEmail,
};
