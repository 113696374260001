import { connect } from 'react-redux';
import { states } from 'stillnovel/store/cart/actions';

import SummaryPresentational from './Summary';

// appliedGiftCards: (...)
// completedAt: (...)
// createdAt: (...)
// currencyCode: (...)
// customAttributes: (...)
// discountApplications: (...)
// email: (...)
// id: (...)
// lineItems: (...)
// lineItemsSubtotalPrice: (...)
// note: (...)
// order: (...)
// orderStatusUrl: (...)
// paymentDue: (...)
// paymentDueV2: (...)
// ready: (...)
// requiresShipping: (...)
// shippingAddress: (...)
// shippingLine: (...)
// subtotalPrice: (...)
// subtotalPriceV2: (...)
// taxExempt: (...)
// taxesIncluded: (...)
// totalPrice: (...)
// totalPriceV2: (...)
// totalTax: (...)
// totalTaxV2: (...)
// type: (...)
// updatedAt: (...)
// webUrl: (...)

const mapStateToProps = store => ({
    totalSub: store.cart.subtotalPrice,
    totalDue: store.cart.totalPrice,
    hasError: store.cart.loadState === states.ERROR,
    isLoading: store.cart.loadState === states.LOADING,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SummaryPresentational);
