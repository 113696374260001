import React from 'react';
import PropTypes from 'prop-types';
import Link from 'stillnovel/components/UI/Link';
import Logo from 'stillnovel/components/UI/Logo';
import Page from 'stillnovel/components/UI/Page';

import styles from './AuthLayout.scss';

const AuthLayout = props => {
    return (
        <Page>
            <div className={styles['auth-wrapper']}>
                <header className={styles['auth-header']}>
                    <Link to="/">
                        <Logo square />
                    </Link>
                </header>
                <article className={styles['auth-content']}>
                    {props.children}
                </article>
            </div>
        </Page>
    );
};

AuthLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthLayout;
